export enum HideFlippersEnum {
  hide_appointment_buttons = 'hide_appointment_buttons',
  hide_general = 'hide_general',
  hide_med_refills = 'hide_med_refills',
  hide_secure_messaging = 'hide_secure_messaging',
  hide_telephonic_options = 'hide_telephonic_options',
  hide_virtual_options = 'hide_virtual_options',
  hide_virtual_physical = 'hide_virtual_physical',
  hide_telephonic_bh = 'hide_telephonic_bh',
  hide_virtual_bh = 'hide_virtual_bh',
  hide_telephonic_chr = 'hide_telephonic_chr',
  hide_virtual_chr = 'hide_virtual_chr',
  incentives_hide_widget_days_remaining = 'incentives_hide_widget_days_remaining',
  incentives_hide_widget_progress = 'incentives_hide_widget_progress',
  incentives_hide_widget_goals = 'incentives_hide_widget_goals',
  incentives_hide_widget_family_earnings = 'incentives_hide_widget_family_earnings',
  hide_exam_lab_appointment = 'hide_exam_lab_appointment',
  hide_lab_work = 'hide_lab_work',
  hide_inperson_options = 'hide_inperson_options',
  no_access_to_mrt_services = 'no_access_to_mrt_services',
  hide_mh_logo = 'hide_mh_logo',
  hide_complete_visit_reasons_list = 'hide_complete_visit_reasons_list',
  hide_wellness_logs = 'hide_wellness_logs',
  hide_wellness_workshops = 'hide_wellness_workshops',
  hide_healthwise_resource = 'hide_healthwise_resource',
  hide_findhelp_org = 'hide_findhelp_org',
}

export enum ShowFlippersEnum {
  connect_my_devices = 'connect_my_devices',
  covid_screening = 'covid_screening',
  enable_qure4u_appointment_buttons = 'enable_qure4u_appointment_buttons',
  enable_pt_scheduling_work_related_question = 'enable_pt_scheduling_work_related_question',
  enable_network_scheduling = 'enable_network_scheduling',
  med_refills_athena = 'med_refills_athena',
  my_allergies = 'my_allergies',
  my_clinical_results = 'my_clinical_results',
  my_immunizations = 'my_immunizations',
  my_medications = 'my_medications',
  my_problems = 'my_problems',
  my_quality_measures = 'my_quality_measures',
  my_risk_profile = 'my_risk_profile',
  my_visit_summaries = 'my_visit_summaries',
  questionnaires = 'questionnaires',
  show_chr = 'show_chr',
  show_covid_appt = 'show_covid_appt',
  show_covid_testing = 'show_covid_testing',
  show_inbody_visit = 'show_inbody_visit',
  show_life_coach = 'show_life_coach',
  view_goals = 'view_goals',
  maintenance_mode = 'maintenance_mode',
  state_appointment_pills = 'state_appointment_pills',
  booked_appointments_preference = 'booked_appointments_preference',
  covid_phase_red = 'covid_phase_red',
  state_of_indiana_landing_page = 'state_of_indiana_landing_page',
  show_eap_services = 'show_eap_services',
  enable_chr_question_send_out = 'enable_chr_question_send_out',
  enable_chr_question_outside_lab = 'enable_chr_question_outside_lab',
  enable_generic_load_icon = 'enable_generic_load_icon',
  legacy_eh_physical = 'legacy_eh_physical'
}
