import React, { useState } from 'react';

import { Button } from '@components/Button';
import { SideBarWidget } from '@components/SideBarWidget';
import { Flipper, ShowFlippersEnum } from '@modules/flippers';

import { ConnectMyDeviceModal } from './components/ConnectMyDeviceModal';
import { locale } from './locale';

type Props = {
  className?: string;
};

const MODAL_ID = 'connect_mydevice_modal';

export function ConnectMyDevice({ className }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Flipper flipper={ShowFlippersEnum.connect_my_devices}>
      <>
        <SideBarWidget className={className}>
          <Button
            onClick={() => {
              setIsOpen(true);
            }}
            variant="outlined"
            color="secondary"
            fullWidth
            aria-haspopup="dialog"
            aria-controls={isOpen ? MODAL_ID : undefined}
          >
            {locale.connect_device}
          </Button>
        </SideBarWidget>
        <ConnectMyDeviceModal open={isOpen} closeModal={() => setIsOpen(false)} />
      </>
    </Flipper>
  );
}
