import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { ReactComponent as MainLogo } from '@assets/main_logo.svg';
import { MAIN_HEADER_RIGHT_ID, MOBILE_MENU_ID } from '@common/constants/viewIds';
import { PATHS } from '@common/routes';
import { AppBar } from '@components/AppBar';
import { Button } from '@components/Button';
import { IconButton } from '@components/IconButton';
import { Link } from '@components/Link';
import { SvgIcon } from '@components/SvgIcon';
import { ChangeLanguage } from '@modules/ChangeLanguage';
import { defaultClinicPhoneSelector, showDefaultClinicPhoneModal } from '@modules/DefaultClinicPhone';
import { unreadCountSelector } from '@modules/Messages';
import { useFetchCategories } from '@modules/categories';
import { Flipper, HideFlippersEnum, useFlippers } from '@modules/flippers';
import { UserProfileState } from '@modules/userProfileData';

import { BURGER_MENU_ID } from '../../constants';
import { EnvironmentNotification } from '../EnvironmentNotification';
import { ProfileMenuDropdown } from '../ProfileMenuDropdown';
import { ProfileSideMenu } from '../ProfileSideMenu';
import { locale } from './locale';

import styles from './MainHeader.module.css';

const cn = classNames.bind(styles);

export interface MainHeaderProps {
  mainMenuToggleHandler: VoidFunction;
  userProfileData: UserProfileState['userProfileData'];
  mainMenuOpen: boolean;
}

const CLINIC_MODAL_ID = 'default_clinic_phone_modal';

export function MainHeader({ mainMenuToggleHandler, userProfileData, mainMenuOpen }: MainHeaderProps) {
  const getFlipperValue = useFlippers();
  const hideLogo = getFlipperValue(HideFlippersEnum.hide_mh_logo);

  useFetchCategories();
  const dispatch = useDispatch();
  const unreadMessages = useSelector(unreadCountSelector);
  const { show } = useSelector(defaultClinicPhoneSelector);

  const handlePhoneClick = useCallback(() => {
    dispatch(showDefaultClinicPhoneModal(true));
  }, [dispatch]);

  const userName = [
    userProfileData.name_prefix,
    userProfileData.preferred_first_name || userProfileData.first_name,
    userProfileData.middle_name,
    userProfileData.last_name,
    userProfileData.name_suffix,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <div className={cn('main-header')}>
      <AppBar className={cn('main-header__inner')}>
        <div className={cn('main-header__left-content')}>
          <nav className={cn('main-header__mobile-menu')} aria-label="primary" id={MOBILE_MENU_ID}>
            <IconButton
              className={cn('main-header__menu-button')}
              onClick={mainMenuToggleHandler}
              title={locale.open_menu}
              aria-controls={mainMenuOpen ? BURGER_MENU_ID : undefined}
              aria-expanded={mainMenuOpen}
              disableRipple
              aria-haspopup
            >
              <SvgIcon icon="burger" className={cn('main-header__menu-icon')} />
            </IconButton>
          </nav>

          {!hideLogo && (
            <Link to="/" ariaLabel="Marathon Health Home Page">
              <MainLogo
                className={cn('main-header__logo-icon')}
                role="presentation"
                title="Marathon Health Official Logo"
              />
            </Link>
          )}
          <EnvironmentNotification className={cn('main-header__environment-notification')} />
        </div>

        <div className={cn('main-header__profile-button')}>
          <ProfileSideMenu
            userName={userName}
            userFirstName={userProfileData.first_name}
            userLastName={userProfileData.last_name}
          />
        </div>

        <nav className={cn('main-header__right-content')} id={MAIN_HEADER_RIGHT_ID} aria-label="utility">
          <ChangeLanguage className="main-header__content-item" />
          <Flipper flipper={HideFlippersEnum.hide_secure_messaging}>
            <div className={cn('main-header__content-item')}>
              <Button
                component={RouterLink}
                to={PATHS.MESSAGES.ROOT}
                className={cn('main-header__content-item--button')}
                startIcon="message"
              >
                {locale.messages_link}
              </Button>
              {!!unreadMessages && <div className={cn('main-header__message-circle')} />}
            </div>
          </Flipper>
          <div className={cn('main-header__content-item')}>
            <Button
              onClick={handlePhoneClick}
              className={cn('main-header__content-item--button')}
              startIcon="phone"
              aria-haspopup="dialog"
              aria-controls={show ? CLINIC_MODAL_ID : undefined}
            >
              {locale.contact_us}
            </Button>
          </div>
          <div className={cn('main-header__content-item')}>
            <ProfileMenuDropdown triggerButtonText={userName} />
          </div>
        </nav>
      </AppBar>
    </div>
  );
}
