import { createAsyncThunk } from '@reduxjs/toolkit';

import { fetch } from '@common/fetch';

import { GlobalWeaverPurposes } from '../constants';
import { AcceptWaiverAcknowledgmentPayload, GlobalWaiverResponse, Waiver } from './types';

export const getWaiverAcknowledgment = createAsyncThunk<GlobalWaiverResponse, GlobalWeaverPurposes>(
  'GlobalWaiver/getWaiverAcknowledgment',
  async (purpose, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/UserWaiverAcknowledgment/waiver-acknowledgment',
        skipAlert: true,
        params: { purpose },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getWaiverAcknowledgments = createAsyncThunk<GlobalWaiverResponse, GlobalWeaverPurposes[]>(
  'GlobalWaiver/getWaiverAcknowledgments',
  async (purposes, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/UserWaiverAcknowledgment/waiver-acknowledgments',
        skipAlert: true,
        params: { purposes },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const acceptWaiverAcknowledgment = createAsyncThunk<unknown, AcceptWaiverAcknowledgmentPayload>(
  'GlobalWaiver/acceptWaiverAcknowledgment',
  async ({ waiverId }, { rejectWithValue }) => {
    try {
      const data = fetch.post({
        url: '/UserWaiverAcknowledgment/waiver-acknowledgment',
        params: { waiverId },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getWaiver = createAsyncThunk<Waiver, GlobalWeaverPurposes>(
  'GlobalWaiver/getWaiver',
  async (purpose, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/UserWaiverAcknowledgment/waiver',
        params: { purpose },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getWaivers = createAsyncThunk<Waiver, GlobalWeaverPurposes[]>(
  'GlobalWaiver/getWaivers',
  async (purposes, { rejectWithValue }) => {
    try {
      const data = fetch.get({
        url: '/UserWaiverAcknowledgment/waivers',
        params: { purposes },
      });
      const res = await data.response;

      return res?.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
