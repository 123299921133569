import { ReactComponent as AccountCircleIcon } from '@assets/account_circle.svg';
import { ReactComponent as AddressMarkerIcon } from '@assets/address_marker.svg';
import { ReactComponent as AdminCircleIcon } from '@assets/admin_circle.svg';
import { ReactComponent as AlarmIcon } from '@assets/alarm.svg';
import { ReactComponent as AlertErrorIcon } from '@assets/alert_error.svg';
import { ReactComponent as AlertSuccessIcon } from '@assets/alert_success.svg';
import { ReactComponent as ArrowDropDown } from '@assets/arrow_dropdown.svg';
import { ReactComponent as ArrowDropUp } from '@assets/arrow_dropup.svg';
import { ReactComponent as ArrowLeftIcon } from '@assets/arrow_left.svg';
import { ReactComponent as ArrowRightIcon } from '@assets/arrow_right.svg';
import { ReactComponent as AttachmentsIcon } from '@assets/attachments.svg';
import { ReactComponent as AvatarIcon } from '@assets/avatar.svg';
import { ReactComponent as BuildingIcon } from '@assets/building.svg';
import { ReactComponent as BurgerIcon } from '@assets/burger.svg';
import { ReactComponent as CalendarIcon } from '@assets/calendar.svg';
import { ReactComponent as CalendarCheckIcon } from '@assets/calendar_check.svg';
import { ReactComponent as CameraIcon } from '@assets/camera.svg';
import { ReactComponent as CancelCalendarIcon } from '@assets/cancel_calendar.svg';
import { ReactComponent as ChallengeCustom } from '@assets/challenges/custom.svg';
import { ReactComponent as ChallengeFitness } from '@assets/challenges/fitness.svg';
import { ReactComponent as ChallengeSleep } from '@assets/challenges/sleep.svg';
import { ReactComponent as ChallengeSteps } from '@assets/challenges/steps.svg';
import { ReactComponent as ChallengeWater } from '@assets/challenges/water.svg';
import { ReactComponent as ChallengeWeight } from '@assets/challenges/weight.svg';
import { ReactComponent as CheckIcon } from '@assets/check.svg';
import { ReactComponent as CheckBoldIcon } from '@assets/check_bold.svg';
import { ReactComponent as CheckboxIcon } from '@assets/checkbox.svg';
import { ReactComponent as CheckboxCheckedIcon } from '@assets/checkbox_checked.svg';
import { ReactComponent as CheckboxOutlined } from '@assets/checkbox_outlined.svg';
import { ReactComponent as CheckboxRounded } from '@assets/checkbox_rounded.svg';
import { ReactComponent as CheckmarkBordered } from '@assets/checkmark_bordered.svg';
import { ReactComponent as CircleChevronLeftIcon } from '@assets/chevron_circle_left.svg';
import { ReactComponent as CircleChevronRightIcon } from '@assets/chevron_circle_right.svg';
import { ReactComponent as ChevronDownIcon } from '@assets/chevron_down.svg';
import { ReactComponent as ChevronDownIconBig } from '@assets/chevron_down_big.svg';
import { ReactComponent as ChevronDuoLeftIcon } from '@assets/chevron_duo_left.svg';
import { ReactComponent as ChevronDuoRightIcon } from '@assets/chevron_duo_right.svg';
import { ReactComponent as ChevronLeftIcon } from '@assets/chevron_left.svg';
import { ReactComponent as ChevronRightIcon } from '@assets/chevron_right.svg';
import { ReactComponent as ChooseNewPasswordIcon } from '@assets/choose_new_password.svg';
import { ReactComponent as CircleCheckIcon } from '@assets/circle_check.svg';
import { ReactComponent as CircleCheckOutlineIcon } from '@assets/circle_check_outline.svg';
import { ReactComponent as ClockIcon } from '@assets/clock_outline.svg';
import { ReactComponent as CloseIcon } from '@assets/close.svg';
import { ReactComponent as CloseAlertIcon } from '@assets/close_alert.svg';
import { ReactComponent as ConeIcon } from '@assets/cone.svg';
import { ReactComponent as DownloadIcon } from '@assets/download.svg';
import { ReactComponent as EmptyBoxIcon } from '@assets/emptybox.svg';
import { ReactComponent as ExternalLinkIcon } from '@assets/external_link.svg';
import { ReactComponent as FilterIcon } from '@assets/filter.svg';
import { ReactComponent as ForgotPasswordIcon } from '@assets/forgot_password.svg';
import { ReactComponent as FileBlankOutlineIcon } from '@assets/form.svg';
import { ReactComponent as GoalIcon } from '@assets/goal.svg';
import { ReactComponent as HappyIcon } from '@assets/happy.svg';
import { ReactComponent as HealthCenterIcon } from '@assets/health_center.svg';
import { ReactComponent as HeartIcon } from '@assets/heart.svg';
import { ReactComponent as HelpOutlineIcon } from '@assets/help_circle_outline.svg';
import { ReactComponent as HidePassword } from '@assets/hidePassword.svg';
import { ReactComponent as HomeIcon } from '@assets/home.svg';
import { ReactComponent as InfoIcon } from '@assets/info.svg';
import { ReactComponent as InfoOutlineIcon } from '@assets/info_circle_outline.svg';
import { ReactComponent as LoadingIcon } from '@assets/loading.svg';
import { ReactComponent as MagnifierIcon } from '@assets/magnifier.svg';
import { ReactComponent as MainLogoIcon } from '@assets/main_logo.svg';
import { ReactComponent as MarathonLogoDry } from '@assets/marathon_logo_dry.svg';
import { ReactComponent as MedicalClipBoardIcon } from '@assets/medicalclipboard.svg';
import { ReactComponent as MedRefillsIcon } from '@assets/meds_refill.svg';
import { ReactComponent as MedsRefillGradientIcon } from '@assets/medsrefill_gradient.svg';
import { ReactComponent as MessageIcon } from '@assets/message.svg';
import { ReactComponent as MicroScopeIcon } from '@assets/microscope.svg';
import { ReactComponent as Mobile } from '@assets/mobile.svg';
import { ReactComponent as MoreIcon } from '@assets/more.svg';
import { ReactComponent as PasswordEmailSentIcon } from '@assets/password_email_sent.svg';
import { ReactComponent as PencilIcon } from '@assets/pencil.svg';
import { ReactComponent as PhoneIcon } from '@assets/phone.svg';
import { ReactComponent as PhysicalIcon } from '@assets/physical.svg';
import { ReactComponent as PlusCircleIcon } from '@assets/plus_circle.svg';
import { ReactComponent as ProviderStethoscopeIcon } from '@assets/provider-stethoscope.svg';
import { ReactComponent as RadioIcon } from '@assets/radio.svg';
import { ReactComponent as RadioCheckedIcon } from '@assets/radio_checked.svg';
import { ReactComponent as RankIcon } from '@assets/rank.svg';
import { ReactComponent as RecordsIcon } from '@assets/records.svg';
import { ReactComponent as RefillIcon } from '@assets/refill.svg';
import { ReactComponent as RefreshIcon } from '@assets/refresh.svg';
import { ReactComponent as ScheduleIcon } from '@assets/schedule.svg';
import { ReactComponent as SearchBigIcon } from '@assets/search-big.svg';
import { ReactComponent as SearchIcon } from '@assets/search.svg';
import { ReactComponent as ShoeIcon } from '@assets/shoe.svg';
import { ReactComponent as ShortDownIcon } from '@assets/short_down.svg';
import { ReactComponent as ShortLeftIcon } from '@assets/short_left.svg';
import { ReactComponent as ShortUpIcon } from '@assets/short_up.svg';
import { ReactComponent as ShowPassword } from '@assets/showPassword.svg';
import { ReactComponent as SignOutIcon } from '@assets/sign_out.svg';
import { ReactComponent as SmartWatchGradientIcon } from '@assets/smart_watch_colored.svg';
import { ReactComponent as SportIcon } from '@assets/sport.svg';
import { ReactComponent as StarIcon } from '@assets/star.svg';
import { ReactComponent as StethoscopeIcon } from '@assets/stethoscope.svg';
import { ReactComponent as TargetIcon } from '@assets/target.svg';
import { ReactComponent as TeethIcon } from '@assets/teeth.svg';
import { ReactComponent as TrashIcon } from '@assets/trash.svg';
import { ReactComponent as TrophyIcon } from '@assets/trophy.svg';
import { ReactComponent as TrophyIconColored } from '@assets/trophy_no_gradient.svg';
import { ReactComponent as TrophyNoGradientIcon } from '@assets/trophy_sm_no_gradient.svg';
import { ReactComponent as UnionIcon } from '@assets/union.svg';
import { ReactComponent as VisionIcon } from '@assets/vision.svg';
import { ReactComponent as WarningIcon } from '@assets/warning.svg';
import { ReactComponent as WarningOutlineIcon } from '@assets/warning_outline.svg';

export const ICONS = {
  accountCircle: AccountCircleIcon,
  adminCircle: AdminCircleIcon,
  addressMarker: AddressMarkerIcon,
  alarm: AlarmIcon,
  alertError: AlertErrorIcon,
  alertSuccess: AlertSuccessIcon,
  arrowLeft: ArrowLeftIcon,
  arrowRight: ArrowRightIcon,
  arrowDropUp: ArrowDropUp,
  arrowDropDown: ArrowDropDown,
  avatar: AvatarIcon,
  building: BuildingIcon,
  attachments: AttachmentsIcon,
  burger: BurgerIcon,
  check: CheckIcon,
  checkBold: CheckBoldIcon,
  chooseNewPassword: ChooseNewPasswordIcon,
  calendar: CalendarIcon,
  cancelCalendar: CancelCalendarIcon,
  calendarCheck: CalendarCheckIcon,
  camera: CameraIcon,
  challengeCustom: ChallengeCustom,
  challengeFitness: ChallengeFitness,
  challengeSleep: ChallengeSleep,
  challengeSteps: ChallengeSteps,
  challengeWater: ChallengeWater,
  challengeWeight: ChallengeWeight,
  checkbox: CheckboxIcon,
  checkboxChecked: CheckboxCheckedIcon,
  checkboxOutlined: CheckboxOutlined,
  checkboxRounded: CheckboxRounded,
  checkmarkBordered: CheckmarkBordered,
  chevronDown: ChevronDownIcon,
  chevronDownBig: ChevronDownIconBig,
  chevronDuoLeft: ChevronDuoLeftIcon,
  chevronDuoRight: ChevronDuoRightIcon,
  chevronLeft: ChevronLeftIcon,
  chevronRight: ChevronRightIcon,
  circleCheck: CircleCheckIcon,
  circleCheckOutline: CircleCheckOutlineIcon,
  circleChevronLeft: CircleChevronLeftIcon,
  circleChevronRight: CircleChevronRightIcon,
  clock: ClockIcon,
  close: CloseIcon,
  cone: ConeIcon,
  closeAlert: CloseAlertIcon,
  download: DownloadIcon,
  emptyBox: EmptyBoxIcon,
  externalLink: ExternalLinkIcon,
  hidePassword: HidePassword,
  heart: HeartIcon,
  healthCenter: HealthCenterIcon,
  helpOutline: HelpOutlineIcon,
  home: HomeIcon,
  filter: FilterIcon,
  forgotPassword: ForgotPasswordIcon,
  form: FileBlankOutlineIcon,
  goal: GoalIcon,
  happy: HappyIcon,
  info: InfoIcon,
  loading: LoadingIcon,
  magnifier: MagnifierIcon,
  mainLogo: MainLogoIcon,
  marathonLogoDry: MarathonLogoDry,
  medicalBoard: MedicalClipBoardIcon,
  medRefill: MedRefillsIcon,
  medRefillGradient: MedsRefillGradientIcon,
  message: MessageIcon,
  microScope: MicroScopeIcon,
  mobile: Mobile,
  more: MoreIcon,
  passwordEmailSent: PasswordEmailSentIcon,
  pencil: PencilIcon,
  phone: PhoneIcon,
  physical: PhysicalIcon,
  plusCircle: PlusCircleIcon,
  rank: RankIcon,
  radio: RadioIcon,
  radioChecked: RadioCheckedIcon,
  records: RecordsIcon,
  refill: RefillIcon,
  refresh: RefreshIcon,
  schedule: ScheduleIcon,
  search: SearchIcon,
  searchBig: SearchBigIcon,
  shortDown: ShortDownIcon,
  shortUp: ShortUpIcon,
  showPassword: ShowPassword,
  signOut: SignOutIcon,
  smartWatch: SmartWatchGradientIcon,
  shoe: ShoeIcon,
  shortLeft: ShortLeftIcon,
  sport: SportIcon,
  star: StarIcon,
  stethoscope: StethoscopeIcon,
  providerStethoscope: ProviderStethoscopeIcon,
  target: TargetIcon,
  teeth: TeethIcon,
  trash: TrashIcon,
  trophy: TrophyIcon,
  trophyColored: TrophyIconColored,
  trophyGradient: TrophyNoGradientIcon,
  union: UnionIcon,
  vision: VisionIcon,
  warning: WarningIcon,
  warningOutline: WarningOutlineIcon,
  infoOutline: InfoOutlineIcon,
} as const;

export type IconKey = keyof typeof ICONS;
