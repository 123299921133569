export const locale = {
  links: {
    messages: 'Messages',
    profile: 'Profile  & Settings',
    sign_out: 'Sign Out',
    covid: 'COVID-19 Screening',
    forms: 'Forms',
    contact_us: 'Contact Us',
  },
  aria: {
    opened: 'Side menu opened',
  },
};
