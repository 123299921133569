import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PROFILE_SIDE_MENU_ID } from '@common/constants/viewIds';
import { PATHS } from '@common/routes';
import { SizesEnum } from '@components/NumberCircle';
import { AnchorEnum, SideMenu } from '@components/SideMenu';
import { IconType } from '@components/SvgIcon';
import { Typography } from '@components/Typography';
import { showDefaultClinicPhoneModal } from '@modules/DefaultClinicPhone';
import { UnreadMessages, isOnlyCurrentUserUnreadMessagesSelector } from '@modules/Messages';
import { HideFlippersEnum, ShowFlippersEnum, useFlippers } from '@modules/flippers';

import { useLogoutMenuItem } from '../../hooks/useLogoutMenuItem';
import { useViewSwitcherMenuitems } from '../../hooks/useViewSwitcherMenuItems';
import { ActionItemInner } from '../ActionItemInner';
import { ProfileButton } from '../ProfileButton';
import { ProfileMenu } from '../ProfileMenu';
import { locale } from './locale';

import styles from './ProfileSideMenu.module.css';

const cn = classNames.bind(styles);

export interface ProfileSideMenuProps {
  userName: string;
  userFirstName: string;
  userLastName: string;
}

export function ProfileSideMenu({ userFirstName, userLastName, userName }: ProfileSideMenuProps) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const getFlipperValue = useFlippers();
  const hideLogo = getFlipperValue(HideFlippersEnum.hide_mh_logo);
  const isOnlyCurrentUserUnreadMessages = useSelector(isOnlyCurrentUserUnreadMessagesSelector);

  const handlePhoneClick = useCallback(() => {
    dispatch(showDefaultClinicPhoneModal(true));
  }, [dispatch]); // eslint-disable-line camelcase

  const viewSwitcherMenuItems = useViewSwitcherMenuitems();

  const logoutMenuItem = useLogoutMenuItem();

  const MENU_ITEMS = useMemo(
    () => [
      {
        icon: 'accountCircle' as IconType,
        to: PATHS.PROFILE.ROOT,
        label: locale.links.profile,
      },
      ...(viewSwitcherMenuItems.length > 1 ? viewSwitcherMenuItems : []),
      {
        icon: 'message' as IconType,
        to: PATHS.MESSAGES.ROOT,
        label: locale.links.messages,
        hideFlipper: HideFlippersEnum.hide_secure_messaging,
        badge: <UnreadMessages className={cn('profile-side-menu__messages')} size={SizesEnum.Small} />,
        menuItemClassName: cn({ 'profile-side-menu__menu-item--badged': !isOnlyCurrentUserUnreadMessages }),
        replace: true,
      },
      {
        icon: 'form' as IconType,
        to: PATHS.FORMS.ROOT,
        label: locale.links.forms,
      },
      {
        action: handlePhoneClick,
        component: <ActionItemInner title={locale.links.contact_us} icon="phone" />,
      },
      {
        icon: 'union' as IconType,
        to: PATHS.COVID_SCREENING.ROOT,
        label: locale.links.covid,
        showFlipper: ShowFlippersEnum.covid_screening,
      },
      logoutMenuItem,
    ],
    [viewSwitcherMenuItems, isOnlyCurrentUserUnreadMessages, handlePhoneClick, logoutMenuItem],
  );

  const handleButtonClick = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setOpen(false);
  }, []);

  const location = useLocation();
  useEffect(() => {
    setOpen(false);
  }, [location]);

  return (
    <div className={cn('profile-side-menu')} id={PROFILE_SIDE_MENU_ID}>
      <ProfileButton
        open={open}
        userFirstName={userFirstName}
        userLastName={userLastName}
        onClick={handleButtonClick}
      />

      <SideMenu
        id="profile_side_menu"
        anchor={AnchorEnum.right}
        open={open}
        onClose={handleCloseMenu}
        hideLogo={hideLogo}
      >
        <div className="sr-only" aria-live="polite" aria-atomic="true">
          {open && locale.aria.opened}
        </div>
        <Typography variant="h2" component="h1" className={cn('profile-side-menu__user-name')}>
          {userName}
        </Typography>
        <div role="menu">
          <ProfileMenu
            menuItems={MENU_ITEMS}
            onClose={handleCloseMenu}
            menuItemClassName={cn('profile-side-menu__menu-item')}
            groupedMenuItemClassName={cn('profile-side-menu__menu-item--grouped')}
            menuItemContentClassName={cn('profile-side-menu__menu-item-content')}
          />
        </div>
      </SideMenu>
    </div>
  );
}
