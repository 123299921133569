import React, { useCallback } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as AppStore } from '@assets/app_store.svg';
import { ReactComponent as GooglePlay } from '@assets/play.svg';
import { StatusEnum } from '@common/constants';
import { APP_STORE_APP, PLAY_MARKET_APP } from '@common/constants/apps';
import { useScreenSize } from '@common/hooks/useScreenSize';
import { Button } from '@components/Button';
import { Link } from '@components/Link';
import { Modal } from '@components/Modal';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { fetchDevices, connectMyDeviceStatusSelector } from '../../store';
import { locale } from './locale';

import styles from './ConnectMyDeviceModal.module.css';

const cn = classNames.bind(styles);

type ConnectMyDeviceProps = {
  open: boolean;
  closeModal: VoidFunction;
};

const TITLE_ID = 'connect_mydevice_modal_title';
const MODAL_ID = 'connect_mydevice_modal';

export function ConnectMyDeviceModal({ open, closeModal }: ConnectMyDeviceProps) {
  const { isMobileView } = useScreenSize();
  const status = useSelector(connectMyDeviceStatusSelector);
  const dispatch = useDispatch();

  const getDevices = useCallback(() => {
    dispatch(fetchDevices());
  }, [dispatch]);

  return (
    <div className={cn('connect-my-device-modal')}>
      <Modal
        modalContentClassName={cn('connect-my-device-modal__content')}
        className={cn('connect-my-device-modal__modal')}
        paperClassName={cn('connect-my-device-modal__container')}
        open={open}
        onClose={closeModal}
        crossbuttonClassName={cn('connect-my-device-modal__close-button')}
        iconClassName={cn('connect-my-device-modal__close-icon')}
        ariaLabelledby={TITLE_ID}
        loading={status === StatusEnum.Pending}
        spinnerClassName={cn('connect-my-device-modal__spinner-container')}
        id={MODAL_ID}
      >
        <>
          {!isMobileView && (
            <div className={cn('connect-my-device-modal__header-container')}>
              <SvgIcon
                viewBox="0 0 63 101"
                className={cn('connect-my-device-modal__smart-watch-icon')}
                icon="smartWatch"
              />
              <div>
                <Typography className={cn('connect-my-device-modal__heading')} variant="h1" id={TITLE_ID}>
                  {locale.heading}
                </Typography>
                <Typography className={cn('connect-my-device-modal__sub-text')}>{locale.subText}</Typography>
                <Button
                  onClick={getDevices}
                  className={cn('connect-my-device-modal__connect-button')}
                  variant="contained"
                >
                  {locale.buttonText}
                </Button>
              </div>
            </div>
          )}
          {isMobileView && (
            <div className={cn('connect-my-device-modal__header-mobile-container')}>
              <div className={cn('connect-my-device-modal__header-mobile')}>
                <Typography className={cn('connect-my-device-modal__heading')} variant="h1" id={TITLE_ID}>
                  {locale.heading}
                </Typography>
                <SvgIcon
                  viewBox="0 0 63 101"
                  className={cn('connect-my-device-modal__smart-watch-icon')}
                  icon="smartWatch"
                />
              </div>
              <Typography className={cn('connect-my-device-modal__sub-text')}>{locale.subText}</Typography>
              <Button
                onClick={getDevices}
                className={cn('connect-my-device-modal__connect-button')}
                variant="contained"
              >
                {locale.buttonText}
              </Button>
            </div>
          )}
          <div className={cn('connect-my-device-modal__actions-container')}>
            <Typography className={cn('connect-my-device-modal__actions-text')}>{locale.actionText}</Typography>
            <div className={cn('connect-my-device-modal__buttons-container')}>
              <Link
                className={cn('connect-my-device-modal__link')}
                to={APP_STORE_APP}
                ariaLabel={locale.get_on_app_store}
              >
                <AppStore role="img" title={locale.appStore} className={cn('connect-my-device-modal__button')} />
              </Link>
              <Link
                className={cn('connect-my-device-modal__link')}
                to={PLAY_MARKET_APP}
                ariaLabel={locale.get_on_google_play}
              >
                <GooglePlay role="img" title={locale.googlePlay} className={cn('connect-my-device-modal__button')} />
              </Link>
            </div>
          </div>

          <div className={cn('connect-my-device-modal__footer')}>
            <SvgIcon className={cn('connect-my-device-modal__footer-icon')} icon="info" />
            <Typography className={cn('connect-my-device-modal__footer-text')}>{locale.footertext}</Typography>
          </div>
        </>
      </Modal>
    </div>
  );
}
