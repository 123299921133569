import { useSelector } from 'react-redux';

import {
  contentByPurposeSelector,
  GlobalWeaverPurposes,
  idByPurposeSelector,
  requiredByPurposeSelector,
} from '@modules/globalWeaver';

export const useTermsOfUseAndPrivacyPolicy = () => {
  const termsOfUseRequired = useSelector((state: RootState) =>
    requiredByPurposeSelector(state, { purpose: GlobalWeaverPurposes.TermsOfUse }),
  );

  const privacyPolicyRequired = useSelector((state: RootState) =>
    requiredByPurposeSelector(state, { purpose: GlobalWeaverPurposes.PrivacyPolicy }),
  );

  const termsOfUseWaiverId = useSelector((state: RootState) =>
    idByPurposeSelector(state, { purpose: GlobalWeaverPurposes.TermsOfUse }),
  );

  const privacyPolicyWaiverId = useSelector((state: RootState) =>
    idByPurposeSelector(state, { purpose: GlobalWeaverPurposes.PrivacyPolicy }),
  );

  const termsOfUse = useSelector((state: RootState) =>
    contentByPurposeSelector(state, { purpose: GlobalWeaverPurposes.TermsOfUse }),
  );

  const privacyPolicy = useSelector((state: RootState) =>
    contentByPurposeSelector(state, { purpose: GlobalWeaverPurposes.PrivacyPolicy }),
  );

  const createBody = (termsBody: string | undefined, privacyBody: string | undefined) =>
    `${termsBody ?? ''}${termsBody && privacyBody ? '\n---\n' : ''}${privacyBody ?? ''}`;

  const createTitle = (termsTitle: string | undefined, privacyTitle: string | undefined) => {
    if (termsTitle && privacyTitle) return `${termsTitle} & ${privacyTitle}`;
    if (termsTitle) return termsTitle;
    if (privacyTitle) return privacyTitle;

    return null;
  };

  const body = createBody(termsOfUse?.body, privacyPolicy?.body);
  const title = createTitle(termsOfUse?.title, privacyPolicy?.title);
  const waiverIds = {
    termsOfUse: termsOfUseRequired ? termsOfUseWaiverId : null,
    privacyPolicy: privacyPolicyRequired ? privacyPolicyWaiverId : null,
  };

  return { required: termsOfUseRequired || privacyPolicyRequired, body, title, waiverIds };
};
