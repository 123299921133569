import { LoginErrorCodes, LoginInfoCodes } from './constants';

export const locale = {
  form: {
    password: 'Password',
    placeholder_password: 'Enter your password',
    username: 'Username or Email',
    placeholder_login: 'Enter your username or email',
    sign_in: 'Sign In',
    forgot_password: 'Forgot Password?',
    register: 'New? Register Now?',
    need_help: 'Need help? Contact us at (866)434-3255',
  },
  description: {
    title: 'Welcome!',
    subtitle: 'Use the Marathon Health Portal to: ',
    use_to_items: [
      'Schedule appointments online',
      'View health center locations and hours',
      'Request prescription refills',
      'Access your medical records and forms',
      'Learn about services available, & more!',
    ],
    additional:
      '*Access is limited to eligible employees and family members of participating employers that have partnered with Marathon Health.',
  },
  download_app: 'Download the Marathon Health app!',
  errors: {
    [LoginErrorCodes.FailedLogin]: {
      title: 'Your password or username is incorrect',
      title2: 'Your account will be locked after one more invalid login attempt.',
      description: 'If you have forgotten your password, please choose “Recover Password” below.',
    },
    [LoginErrorCodes.AccountLocked]: {
      title: 'Your account is locked',
      description:
        'You can reset your password or call our Member Relations Team at 866-434-3255 to unlock your account.',
      description2:
        'You can reset your password in {{blockTimeToReset}} minutes or CALL our Member Relations Team at 866-434-3255.',
    },
    [LoginErrorCodes.NotOldEnough]: {
      title: 'Sorry, you must be over 18 to sign in.',
    },
    [LoginErrorCodes.NoCurrentEligibility]: {
      title: 'Sorry, you do not currently have active eligibility with Marathon Health and cannot access the portal.',
      description: 'If you have any questions, please call 866-434-3255.',
    },
    [LoginErrorCodes.RegistrationLinkInvalid]: {
      title: 'Invalid registration link.',
    },
    [LoginErrorCodes.NoMatchFound]: {
      title: 'Account not found.',
      description: 'Please sign in with your credentials or register if you are new to the Marathon Health Portal.',
    },
    [LoginErrorCodes.Default]: {
      title: 'Something Went Wrong',
    },
  },
  info: {
    [LoginInfoCodes.InactivityLogout]: {
      title: 'You have been signed out due to inactivity.',
    },
    [LoginInfoCodes.AlreadyRegistered]: {
      title: "You've already registered for the portal. Please sign in to your existing account.",
    },
  },
  impersonation: {
    enter: 'Impersonate enter',
    retry: 'Retry',
    go_back_admin: 'Go back to Admin Portal',
  },
  close: 'CLOSE',
  recover_password: 'RECOVER PASSWORD',
  sign_up_complete: {
    title: 'Congratulations! You are ready to start using the site.',
    subtitle: 'You should receive an email summary shortly. ',
  },
};
