import React, { ReactNode, forwardRef } from 'react';

import classNames from 'classnames/bind';

import { FormHelperText } from '@components/FormHelperText';
import { Skeleton } from '@components/Skeleton';

import { locale } from './locale';

import styles from './FieldRow.module.css';

const cn = classNames.bind(styles);

type sizes = 'big' | 'small' | 'xsmall' | 'msmall';
type font = 'xs' | 's' | 'm' | 'l' | 'xxl';

export type FieldRowProps = {
  children: ReactNode;
  label?: ReactNode;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  className?: string;
  loading?: boolean;
  showRquiredText?: boolean;
  size?: sizes;
  font?: font;
  labelColor?: 'gray' | 'dark' | 'error';
  id?: string;
  role?: string;
  ariaLabel?: string;
};

export function FieldRowComponent(
  {
    children,
    label,
    required,
    helperText,
    className,
    error,
    loading,
    size = 'big',
    font = 'm',
    showRquiredText,
    labelColor = 'dark',
    role,
    ariaLabel,
    id,
  }: FieldRowProps,
  ref: React.Ref<HTMLDivElement>,
) {
  if (loading) {
    return (
      <Skeleton
        width="100%"
        height="3rem"
        className={cn('field', {
          [`field--size-${size}`]: size,
          [`field--font-${font}`]: font,
        })}
      />
    );
  }

  return (
    <div
      className={cn('field', className, {
        [`field--size-${size}`]: size,
        [`field--font-${font}`]: font,
      })}
      role={role}
      ref={ref}
      aria-label={ariaLabel}
    >
      {label && (
        <div className={cn('field__label', { [`field__label--${labelColor}`]: labelColor })} id={id}>
          {label}

          {required && (
            <span
              className={cn('field__asterisk', {
                [`field__asterisk--size-${size}`]: size,
                [`field__asterisk--font-${font}`]: font,
              })}
            >
              *
            </span>
          )}
        </div>
      )}

      {showRquiredText && (
        <div className={cn('field__required')}>
          <span className={cn('field__asterisk', 'field__asterisk--small')}>*</span> {locale.required_fields}
        </div>
      )}

      <div className={cn('field__body')}>{children}</div>

      {helperText && <FormHelperText error={error} helperText={helperText} />}
    </div>
  );
}

export const FieldRow = forwardRef(FieldRowComponent);
