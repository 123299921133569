import React, { useMemo } from 'react';

import classNames from 'classnames/bind';

import { useQueryParamsObject } from '@common/router';
import { getLocale } from '@common/utils/locale';
import { useShowFamilyMembers } from '@modules/LinkedFamilyMembers';

import { useGetAthenaValues } from '../../../hooks/useGetAthenaValues';
import { MessageType } from '../../../store';
import { locale } from '../locale';

import styles from '../MessageBox.module.css';

const cn = classNames.bind(styles);

type Props = {
  message: MessageType;
};

export const SenderRecipient = ({ message }: Props) => {
  const { athenaProviderName, athenaDepartmentName } = useGetAthenaValues(
    message.athena_provider_id,
    message.athena_department_id,
  );

  const { familyMembers } = useShowFamilyMembers(true);
  const { memberId } = useQueryParamsObject();

  const member = familyMembers.find((member) => member.id === Number(memberId));
  const memberName = `${member?.first_name ?? ''} ${member?.last_name ?? ''}`;

  const senderOrRecipient = useMemo(() => {
    const athenaValue = athenaProviderName || athenaDepartmentName;
    const sender = athenaValue || message.sender_name;
    const recipients = athenaValue || message?.recipients?.join('; ') || locale.member_relation_team;

    if (memberName === message.sender_name) {
      return recipients ? getLocale(locale.recipient, { recipients }) : null;
    }

    return sender ? getLocale(locale.sender, { sender }) : null;
  }, [athenaDepartmentName, athenaProviderName, message?.recipients, message.sender_name, memberName]);

  return senderOrRecipient ? <div className={cn('message-box__sender')}>{senderOrRecipient}</div> : null;
};
