import React from 'react';

import { Checkbox, CheckboxProps } from '@components/Checkbox';

import { useController, UseControllerProps, FieldValues, RegisterOptions } from '../hooks';

type CheckboxWithControlProps<FormValues extends FieldValues> = {
  name: string;
  required?: boolean;
  validate?: RegisterOptions<FormValues>['validate'];
} & UseControllerProps<FormValues> &
  Omit<CheckboxProps, 'checked' | 'validate'>;

export function CheckboxWithControl<FormValues extends FieldValues>({
  control,
  name,
  required,
  validate,
  onChange,
  ...restProps
}: CheckboxWithControlProps<FormValues>) {
  const {
    field: { onChange: fieldOnchange, value },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required, validate },
  });

  const handleChange: CheckboxProps['onChange'] = (event, checked) => {
    fieldOnchange(event, checked);
    onChange?.(event, checked);
  };

  return (
    <Checkbox
      {...restProps}
      onChange={handleChange}
      value={value}
      name={name}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
    />
  );
}
