export enum GlobalWeaverPurposes {
  TermsOfUse = 'terms-of-use',
  PrivacyPolicy = 'privacy-policy',
  IncentiveMultipleActivePrograms = 'incentives-multiple-active-programs',
  SchedulingFilter = 'scheduling-filter',
  PortalWelcome = 'portal-welcome',
  SchedulePhoneVideoOneButton = 'scheduling-phone-video-one-button',
  SchedulingProviderBios = 'scheduling-provider-bios',
  LanguageAssistanceSpanish = 'language-assistance-spanish',
}
