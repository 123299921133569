import { MailboxTypeEnum } from '../../constants';

export const locale = {
  read: ' - Read',
  unread: ' - Unread',
  all_your_message: 'All your messages, replies and activity will be here.',
  member_relation_team: 'Member Relations',
  mailbox_types: {
    [MailboxTypeEnum.Inbox]: 'inbox',
    [MailboxTypeEnum.Draft]: 'draftbox',
    [MailboxTypeEnum.Archive]: 'archivebox',
    [MailboxTypeEnum.Sent]: 'sentbox',
  },
  empty_box: 'Your {{mailbox}} is empty',
  empty_search_box: 'No results found for "{{search}}".',
  empty_search_box_try_again: 'Try adjusting the search terms.',
  conversations: 'Conversations',
  has_attachments: 'Has attachments',
  from: 'Conversation with {{from}},',
  sent: 'Sent on: {{date}},',
  saved_on: 'Draft saved on: {{date}},',
  subject: 'Subject: {{subject}},',
  conversation_list_aria: 'Conversation with {{sender}}, {{recentMessageAt}} Subject: {{subject}}',
};
