import React from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { unreadCountSelector } from '@modules/Messages';
import { Flipper, HideFlippersEnum } from '@modules/flippers';

import styles from './ProfileButton.module.css';

const cn = classNames.bind(styles);

export interface ProfileButtonProps {
  userFirstName: string;
  userLastName: string;
  onClick?: (event?: React.SyntheticEvent) => void;
  open?: boolean;
}

export function ProfileButton({ open, userFirstName, userLastName, onClick }: ProfileButtonProps) {
  const unreadMessages = useSelector(unreadCountSelector);

  return (
    <button
      className={cn('profile-button')}
      onClick={onClick}
      type="button"
      aria-haspopup="dialog"
      aria-expanded={open}
      aria-label="Profile"
      aria-controls="profile_side_menu"
    >
      {`${userFirstName[0]}${userLastName[0]}`}
      {!!unreadMessages && (
        <Flipper flipper={HideFlippersEnum.hide_secure_messaging}>
          <span className={cn('profile-button__message-circle')} />
        </Flipper>
      )}
    </button>
  );
}
