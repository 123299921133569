import React, { ChangeEvent, KeyboardEventHandler, useCallback } from 'react';

import CheckboxMaterial, { CheckboxProps as CheckboxPropsMaterial } from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel, {
  FormControlLabelProps as FormControlLabelPropsMaterial,
} from '@mui/material/FormControlLabel';
import classNames from 'classnames/bind';

import { FormHelperText } from '@components/FormHelperText';
import { SvgIcon } from '@components/SvgIcon';

import styles from './Checkbox.module.css';

const cn = classNames.bind(styles);

export type CheckboxProps = {
  className?: string;
  checkBoxClassName?: string;
  checkBoxIconClassName?: string;
  labelClassName?: string;
  checkedLabelColored?: boolean;
  label?: FormControlLabelPropsMaterial['label'];
  variant?: 'outlined' | 'standard';
  helperText?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  checked?: boolean;
  error?: boolean;
  disableTypography?: boolean;
} & Pick<
  CheckboxPropsMaterial,
  'onChange' | 'required' | 'disabled' | 'value' | 'name' | 'disableRipple' | 'focusVisibleClassName'
>;

export function Checkbox({
  className,
  checkBoxClassName,
  checkBoxIconClassName,
  labelClassName,
  disabled = false,
  label,
  name = '',
  onChange,
  required = false,
  value = false,
  labelPlacement,
  checkedLabelColored = false,
  disableRipple = false,
  variant = 'standard',
  checked = !!value,
  helperText = '',
  error = false,
  disableTypography = false,
  focusVisibleClassName,
}: CheckboxProps) {
  const handleKeyDown = useCallback<KeyboardEventHandler>(
    (event) => {
      if (event.code === 'Enter') {
        onChange?.(event as unknown as ChangeEvent<HTMLInputElement>, !checked);
      }
    },
    [onChange, checked],
  );

  const checkbox = (
    <CheckboxMaterial
      disabled={disabled}
      required={required}
      disableRipple={disableRipple}
      className={cn('checkbox__input', checkBoxClassName, {
        'checkbox__input--disabled': disabled,
      })}
      focusVisibleClassName={cn('checkbox__focus-visible', focusVisibleClassName)}
      checkedIcon={<SvgIcon className={cn('checkbox__checked-icon')} icon="checkboxChecked" />}
      icon={<SvgIcon className={cn('checkbox__unchecked-icon', checkBoxIconClassName)} icon="checkbox" />}
      checked={checked}
      value={value}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      name={name}
      color="default"
    />
  );

  return (
    <FormControl variant={variant} required={required} className={cn('checkbox', className)}>
      <FormControlLabel
        disabled={disabled}
        control={checkbox}
        labelPlacement={labelPlacement}
        className={cn('checkbox__label', labelClassName, {
          'checkbox__label--disabled': disabled,
          'checkbox__label--checked-colored': checked && checkedLabelColored,
        })}
        label={label}
        disableTypography={disableTypography}
      />

      {!!helperText && <FormHelperText error={error} helperText={helperText} className={cn('checkbox__text')} />}
    </FormControl>
  );
}
