import { createSelector } from 'reselect';

import { GlobalWeaverPurposes } from '../constants';

const globalWaiverSelector = (state: RootState) => state.globalWaiver;
const isImpersonationSelector = (state: RootState) => state.login.impersonation;
const purposeSelector = (_: unknown, { purpose }: { purpose: GlobalWeaverPurposes }) => purpose;
export const statusByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].status,
);
export const signedByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].signed,
);

export const forceRunByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].forceRun,
);

export const dataByPurposeSelector = createSelector(
  globalWaiverSelector,
  purposeSelector,
  (globalWaiver, purpose) => globalWaiver[purpose].data,
);

export const contentByPurposeSelector = createSelector(dataByPurposeSelector, (data) => ({
  body: data?.supplemental_waiver?.body,
  title: data?.supplemental_waiver?.title,
}));

export const idByPurposeSelector = createSelector(dataByPurposeSelector, (data) => data?.supplemental_waiver?.id);

export const isAcknowledgeByPurposeSelector = createSelector(dataByPurposeSelector, (data) => data?.is_acknowledged);

export const requiredByPurposeSelector = createSelector(
  signedByPurposeSelector,
  isAcknowledgeByPurposeSelector,
  forceRunByPurposeSelector,
  isImpersonationSelector,
  (signed, isAcknowledge, forceRun, impersonation) =>
    !signed && (isAcknowledge === false || forceRun) && !impersonation,
);
