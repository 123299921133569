import React, { useCallback, useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { SignModal } from '@components/SignModals';
import { logout } from '@modules/Login';
import { currentEligibilitySelector, supplementalWaiverRequiredSelector } from '@modules/userProfileData';

import { locale } from './locale';
import {
  acceptSupplementalWaiver,
  getAcceptedSupplementalWaiverCurrentEligibilitySelector,
  getSupplementalWaiverData,
  getSupplementalWaiverTextSelector,
} from './store';

export const SupplementalWaiver = () => {
  const [isOpenSupplementalWaiver, setSupplementalWaiver] = useState(false);
  const dispatch = useDispatch();
  const { handleSubmit, control, watch } = useForm();
  const agreement = watch('agreement');
  const currentEligibility = useSelector(currentEligibilitySelector);
  const isSupplementalWaiverRequired = useSelector(supplementalWaiverRequiredSelector);
  const acceptedSupplementalWaiverCurrentEligibility = useSelector(
    getAcceptedSupplementalWaiverCurrentEligibilitySelector,
  );
  const { title, body } = useSelector(getSupplementalWaiverTextSelector);

  const handleOnClose = useCallback(() => {
    dispatch(logout({ inactivityLogout: false }));
  }, [dispatch]);

  useEffect(() => {
    if (isSupplementalWaiverRequired && !acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(true);
    }
  }, [isSupplementalWaiverRequired, acceptedSupplementalWaiverCurrentEligibility, currentEligibility?.id]);

  const onSubmit = useCallback(() => {
    // TODO-ekvasiuk check the data
    if (currentEligibility?.id) {
      dispatch(acceptSupplementalWaiver({ id: currentEligibility?.id }));
    }
  }, [dispatch, currentEligibility?.id]);

  const getData = useCallback(() => {
    dispatch(getSupplementalWaiverData());
  }, [dispatch]);

  useEffect(() => {
    if (isSupplementalWaiverRequired && !acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(true);
    }
  }, [isSupplementalWaiverRequired, acceptedSupplementalWaiverCurrentEligibility, currentEligibility?.id]);

  useEffect(() => {
    if (acceptedSupplementalWaiverCurrentEligibility) {
      setSupplementalWaiver(false);
    }
  }, [acceptedSupplementalWaiverCurrentEligibility]);

  return (
    <SignModal
      open={isOpenSupplementalWaiver}
      onClose={handleOnClose}
      onSubmit={handleSubmit(onSubmit)}
      getData={getData}
      title={title}
      body={body}
      cancelButtonTitle={locale.cancel}
      signButtonTitle={locale.accept}
      agreementFieldName="agreement"
      agreementTitle={locale.agreement}
      control={control}
      disabled={!agreement}
    />
  );
};
