import { ActionReducerMapBuilder, createSlice, current, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { UserResponseType } from '@common/types';
import { logout, logoutWithoutRevoke } from '@modules/Login';

import { EMPTY_GENDER_ID, FAMILY_MEMBERS_COLORS_ARRAY } from '../constants';
import { FamilyMemberColor } from '../types';
import {
  changeCurrentEligibility,
  getActiveEligibilities,
  getFamilyMemberProfile,
  getFamilyMembers,
  getHomeAddress,
  getUserProfile,
  saveCredentials,
  saveFamilyMembers,
  saveHomeAddress,
  saveUserProfile,
  getSchedulableFamilyMembers,
  savePersonalInformation,
  saveContactInformation,
  saveEmergencyContactInformation,
  saveCustomSettingsInformation,
} from './actions';
import { FamilyMemberProfileType, UserProfileState } from './types';
import { saveUserDataFulfilled, saveUserDataPending, saveUserDataRejected } from './utils';

const initialState: UserProfileState = {
  activeEligibilities: [],
  activeEligibilitiesStatus: StatusEnum.Uninitialized,
  userProfileData: {
    id: 0,
    user_name: '',
    email: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    gender: '',
    username: '',
    born_on: '',
    pronouns: '',
    preferred_first_name: '',
    home_phone_number: '',
    cell_phone_number: '',
    social_security_number: '',
    preferred_order_of_contact: '',
    wellness_id: '',
    prefix: '',
    suffix: '',
    name_prefix: '',
    name_suffix: '',
    primary_language: '',
    work_phone_number: '',
    work_email: '',
    emergency_contact_first_name: '',
    emergency_contact_last_name: '',
    emergency_contact_phone_number: '',
    uscis_number: '',
    individual_taxpayer_identification_number: '',
    private_insurance_carrier_name: '',
    private_insurance_group_id_number: '',
    private_insurance_member_id_number: '',
    private_insurance_rx_bin: '',
    private_insurance_rx_pcn: '',
    system_display_name: '',
    time_zone: '',
    nickname: '',
    health_coach_form_version: '',
    refused_to_give_email: false,
    consent_to_call: false,
    consent_to_email: false,
    consent_to_text: false,
    state_of_indiana_clinic_access: null,
    state_of_indiana_wellness_only: null,
  },
  homeAddress: {},
  homeAddressStatus: StatusEnum.Uninitialized,
  homeAddressSaveStatus: StatusEnum.Uninitialized,
  userProfileStatus: StatusEnum.Uninitialized,
  saveStatus: StatusEnum.Uninitialized,
  familyMembersStatus: StatusEnum.Uninitialized,
  schedulableFamilyMembersStatus: StatusEnum.Uninitialized,
  saveFamilyMemberStatus: StatusEnum.Uninitialized,
  currentEligibility: null,
  hcbbUrl: null,
  roles: [],
  familyMembers: [],
  familyMembersColors: {},
  schedulableFamilyMembers: [],
  familyMembersProfiles: {},
  isActiveIncentiveProgram: false,
  isPreviewableIncentiveProgram: false,
  isSupplementalWaiverRequired: null,
  ianaTimeZone: null,
  impersonatorIanaTimeZone: null,
  isRestricted: false,
  isOnBoardingPassed: true,
  changeCurrentEligibilityId: null,
  familyMemberId: null,
  saveRxWorthy: null,
};

const applyUserProfileFromStorage = (state: UserProfileState, userData: UserResponseType): UserProfileState => ({
  ...state,
  userProfileData: {
    ...state.userProfileData,
    ...userData.user,
    name_prefix: userData.user.prefix,
    name_suffix: userData.user.suffix,
  },
  roles: userData.user_auth.roles,
  isOnBoardingPassed: userData.is_on_boarding_passed,
  isActiveIncentiveProgram: userData.user_auth.is_active_incentive_program,
  isPreviewableIncentiveProgram: userData.user_auth.is_previewable_incentive_program,
  currentEligibility: userData.user_auth.current_eligibility,
  isSupplementalWaiverRequired: userData.user_auth.is_supplemental_waiver_required,
  hcbbUrl: userData.user_auth.hcbb_url,
  ianaTimeZone: userData.user.iana_time_zone,
  isRestricted: userData.user.is_restricted,
  familyMemberId: userData.user.id,
  impersonatorIanaTimeZone: userData.user_auth.impersonator?.iana_time_zone,
  saveRxWorthy: userData.user_auth.save_rx_worthy,
});

export const resetStoreActions = (
  builder: ActionReducerMapBuilder<UserProfileState>,
  resetAction: () => UserProfileState,
) => {
  builder.addCase(logout.fulfilled, resetAction);
  builder.addCase(logoutWithoutRevoke.fulfilled, resetAction);
};

export const userProfileSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setProfileFromStorage: (state: UserProfileState, action: PayloadAction<UserResponseType>) =>
      applyUserProfileFromStorage(current(state), action.payload),
    resetSaveStatus(state: UserProfileState) {
      state.saveStatus = StatusEnum.Uninitialized;

      state.homeAddressSaveStatus = StatusEnum.Uninitialized;
      state.homeAddress = initialState.homeAddress;
    },

    changeCurrentEligibilityStart(state: UserProfileState, action: PayloadAction<number | null>) {
      state.changeCurrentEligibilityId = action.payload;
    },

    selectFamilyMemberId(state: UserProfileState, action: PayloadAction<number>) {
      state.familyMemberId = action.payload;
      state.homeAddressSaveStatus = StatusEnum.Uninitialized;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getActiveEligibilities.pending, (state) => {
      state.activeEligibilitiesStatus = StatusEnum.Pending;
    });
    builder.addCase(getActiveEligibilities.fulfilled, (state, action) => {
      state.activeEligibilitiesStatus = StatusEnum.Fulfilled;
      state.activeEligibilities = action.payload;
    });
    builder.addCase(getActiveEligibilities.rejected, (state) => {
      state.activeEligibilitiesStatus = StatusEnum.Rejected;
    });

    builder.addCase(changeCurrentEligibility.pending, (state) => {
      state.activeEligibilitiesStatus = StatusEnum.Pending;
      state.changeCurrentEligibilityId = null;
    });
    builder.addCase(changeCurrentEligibility.fulfilled, (state, action) => {
      state.activeEligibilitiesStatus = StatusEnum.Fulfilled;
      state.familyMembersStatus = StatusEnum.Uninitialized;
      state.familyMembers = [];
      state.schedulableFamilyMembersStatus = StatusEnum.Uninitialized;
      state.schedulableFamilyMembers = [];
      state.currentEligibility = action.payload.user_auth.current_eligibility;
      state.isSupplementalWaiverRequired = action.payload.user_auth.is_supplemental_waiver_required;
      state.hcbbUrl = action.payload.user_auth.hcbb_url;
      state.isActiveIncentiveProgram = action.payload.user_auth.is_active_incentive_program;
      state.saveRxWorthy = action.payload.user_auth.save_rx_worthy;
      // TODO-ekvasiuk change to flipper when it is ready
      state.userProfileData.state_of_indiana_clinic_access = action.payload.user.state_of_indiana_clinic_access;
      state.userProfileData.state_of_indiana_wellness_only = action.payload.user.state_of_indiana_wellness_only;
    });
    builder.addCase(changeCurrentEligibility.rejected, (state) => {
      state.activeEligibilitiesStatus = StatusEnum.Rejected;
    });

    builder.addCase(getUserProfile.pending, (state) => {
      state.userProfileStatus = StatusEnum.Pending;
    });
    builder.addCase(getUserProfile.fulfilled, (state, action) => {
      state.userProfileStatus = StatusEnum.Fulfilled;
      state.userProfileData = {
        ...state.userProfileData,
        ...action.payload,
        gender: action.payload.gender ? action.payload.gender : EMPTY_GENDER_ID,
      };
    });
    builder.addCase(getUserProfile.rejected, (state) => {
      state.userProfileStatus = StatusEnum.Rejected;
    });

    builder.addCase(getHomeAddress.pending, (state) => {
      state.homeAddressStatus = StatusEnum.Pending;
    });
    builder.addCase(getHomeAddress.fulfilled, (state, action) => {
      state.homeAddressStatus = StatusEnum.Fulfilled;
      state.homeAddress = action.payload.address;
    });
    builder.addCase(getHomeAddress.rejected, (state) => {
      state.homeAddressStatus = StatusEnum.Rejected;
    });

    builder.addCase(saveHomeAddress.pending, (state) => {
      state.homeAddressSaveStatus = StatusEnum.Pending;
    });
    builder.addCase(saveHomeAddress.fulfilled, (state, action) => {
      state.homeAddressSaveStatus = StatusEnum.Fulfilled;
      state.homeAddress = {
        ...state.homeAddress,
        ...action.meta.arg.address,
      };
    });
    builder.addCase(saveHomeAddress.rejected, (state) => {
      state.homeAddressSaveStatus = StatusEnum.Rejected;
    });

    builder.addCase(saveUserProfile.pending, saveUserDataPending);
    builder.addCase(saveUserProfile.fulfilled, saveUserDataFulfilled);
    builder.addCase(saveUserProfile.rejected, saveUserDataRejected);

    builder.addCase(savePersonalInformation.pending, saveUserDataPending);
    builder.addCase(savePersonalInformation.fulfilled, saveUserDataFulfilled);
    builder.addCase(savePersonalInformation.rejected, saveUserDataRejected);

    builder.addCase(saveContactInformation.pending, saveUserDataPending);
    builder.addCase(saveContactInformation.fulfilled, saveUserDataFulfilled);
    builder.addCase(saveContactInformation.rejected, saveUserDataRejected);

    builder.addCase(saveEmergencyContactInformation.pending, saveUserDataPending);
    builder.addCase(saveEmergencyContactInformation.fulfilled, saveUserDataFulfilled);
    builder.addCase(saveEmergencyContactInformation.rejected, saveUserDataRejected);

    builder.addCase(saveCustomSettingsInformation.pending, saveUserDataPending);
    builder.addCase(saveCustomSettingsInformation.fulfilled, saveUserDataFulfilled);
    builder.addCase(saveCustomSettingsInformation.rejected, saveUserDataRejected);

    builder.addCase(saveCredentials.pending, saveUserDataPending);
    builder.addCase(saveCredentials.fulfilled, saveUserDataFulfilled);
    builder.addCase(saveCredentials.rejected, saveUserDataRejected);

    builder.addCase(getFamilyMembers.pending, (state) => {
      state.familyMembersStatus = StatusEnum.Pending;
    });
    builder.addCase(getFamilyMembers.fulfilled, (state, action) => {
      state.familyMembers = action.payload;
      state.familyMembersStatus = StatusEnum.Fulfilled;

      state.familyMembersColors = [state.userProfileData, ...action.payload].reduce<FamilyMemberColor>(
        (acc, current, index) => {
          acc[current.id] = FAMILY_MEMBERS_COLORS_ARRAY[index % FAMILY_MEMBERS_COLORS_ARRAY.length];

          return acc;
        },
        {},
      );
    });
    builder.addCase(getFamilyMembers.rejected, (state) => {
      state.familyMembersStatus = StatusEnum.Rejected;
    });

    builder.addCase(getSchedulableFamilyMembers.pending, (state) => {
      state.schedulableFamilyMembersStatus = StatusEnum.Pending;
    });
    builder.addCase(getSchedulableFamilyMembers.fulfilled, (state, action) => {
      state.schedulableFamilyMembers = action.payload;
      state.schedulableFamilyMembersStatus = StatusEnum.Fulfilled;
    });
    builder.addCase(getSchedulableFamilyMembers.rejected, (state) => {
      state.schedulableFamilyMembersStatus = StatusEnum.Rejected;
    });

    builder.addCase(saveFamilyMembers.pending, (state) => {
      state.saveFamilyMemberStatus = StatusEnum.Pending;
    });
    builder.addCase(saveFamilyMembers.fulfilled, (state, action) => {
      state.saveFamilyMemberStatus = StatusEnum.Fulfilled;
      state.familyMembers = action.meta.arg;
    });
    builder.addCase(saveFamilyMembers.rejected, (state) => {
      state.saveFamilyMemberStatus = StatusEnum.Rejected;
    });

    builder.addCase(getFamilyMemberProfile.pending, (state, action) => {
      const { userId } = action.meta.arg;
      const familyMemberState = state.familyMembersProfiles[userId] ?? ({} as FamilyMemberProfileType);
      state.familyMembersProfiles[userId] = {
        ...familyMemberState,
        gettingStatus: StatusEnum.Pending,
      };
    });
    builder.addCase(getFamilyMemberProfile.fulfilled, (state, action) => {
      const { userId } = action.meta.arg;
      const familyMemberState = state.familyMembersProfiles[userId] ?? ({} as FamilyMemberProfileType);
      state.familyMembersProfiles[userId] = {
        ...familyMemberState,
        gettingStatus: StatusEnum.Fulfilled,
        data: action.payload,
      };
    });
    builder.addCase(getFamilyMemberProfile.rejected, (state, action) => {
      const { userId } = action.meta.arg;
      const familyMemberState = state.familyMembersProfiles[userId] ?? ({} as FamilyMemberProfileType);
      state.familyMembersProfiles[userId] = {
        ...familyMemberState,
        gettingStatus: StatusEnum.Rejected,
      };
    });

    resetStoreActions(builder, () => initialState);
  },
});

export const { setProfileFromStorage, resetSaveStatus, changeCurrentEligibilityStart, selectFamilyMemberId } =
  userProfileSlice.actions;
export const userProfileReducer = userProfileSlice.reducer;
