import React, { useCallback } from 'react';

import classNames from 'classnames/bind';

import { NumberCircle } from '@components/NumberCircle';
import { OptionType, Select, SelectProps } from '@components/Select';
import { FamilyMemberTypeWithName } from '@modules/userProfileData';

import { locale } from './locale';

import styles from './LinkedFamilyMembers.module.css';

const cn = classNames.bind(styles);

export type LinkedFamilyMembersProps = {
  className?: string;
  onSelectFamilyMember: (number: number) => void;
  selectedMemberId: Nullable<number>;
  showMemberAmount?: boolean;
  fullWidth?: boolean;
  showType?: boolean;
  disabled?: boolean;
  renderOption?: SelectProps<FamilyMemberTypeWithName>['renderOption'];
  label?: SelectProps['label'];
  required?: SelectProps['required'];
  familyMembers: Array<FamilyMemberTypeWithName>;
  labelId?: string;
  renderCustomOption?: (option: FamilyMemberTypeWithName, showType?: boolean) => React.ReactNode;
  onDropDownOpen?: VoidFunction;
  placeholder?: string;
  allOption?: OptionType;
};

const renderShowTypeValue = (option: FamilyMemberTypeWithName, showType?: boolean) => {
  let result = '';

  if(showType) {
    result = `${option.eligibility_type_name}: `; 
  }

  result += `${option.preferred_first_name ? option.preferred_first_name : option.first_name} ${option.last_name}`;
  return result;
};

export const LinkedFamilyMembers = ({
  className,
  onSelectFamilyMember,
  selectedMemberId,
  showMemberAmount,
  fullWidth,
  showType,
  renderOption,
  disabled,
  label,
  required,
  labelId,
  familyMembers = [],
  renderCustomOption,
  onDropDownOpen,
  allOption,
  placeholder,
}: LinkedFamilyMembersProps) => {
  const handleChange = useCallback<NonNullable<SelectProps<FamilyMemberTypeWithName>['onChange']>>(
    (ev) => {
      onSelectFamilyMember?.((ev.target.value as Nullable<UnknownObject>)?.id as number);
    },
    [onSelectFamilyMember],
  );

  const renderValue = useCallback<NonNullable<SelectProps<FamilyMemberTypeWithName>['renderValue']>>(
    (value) => (
      <div className={cn('linked-family-members__value')}>
        {value ? renderShowTypeValue(value as FamilyMemberTypeWithName, showType) : placeholder || locale.value}
        {showMemberAmount && (
          <NumberCircle className={cn('linked-family-members__count')} count={familyMembers.length} ariaHidden />
        )}
      </div>
    ),
    [familyMembers.length, showMemberAmount, showType, placeholder],
  );

  const renderOptionValue = useCallback(
    (option: FamilyMemberTypeWithName) => {
      if (renderCustomOption) {
        return renderCustomOption?.(option, showType);
      }

      return renderShowTypeValue(option, showType);
    },
    [renderCustomOption, showType],
  );

  return (
    <div className={className}>
      <Select
        className={cn('linked-family-members')}
        selectClassName={cn('linked-family-members__select')}
        selectRootClassName={cn('linked-family-members__select-root')}
        renderOptionValue={renderOptionValue}
        variant="standard"
        disableUnderline
        menuClassName={cn('linked-family-members__outlined')}
        value={selectedMemberId || ''}
        options={familyMembers}
        label={label}
        required={required}
        disabled={disabled}
        selectOption
        hideCurrentOption
        renderValue={renderValue}
        onOpen={onDropDownOpen}
        onChange={handleChange}
        labelId={labelId || ''}
        fullWidth={fullWidth}
        renderOption={renderOption}
        noneOption={allOption}
        SelectDisplayProps={{
          role: 'listbox',
        }}
      />
    </div>
  );
};
