import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as MainLogo } from '@assets/main_logo.svg';
import { Drawer } from '@components/Drawer';
import { Link } from '@components/Link';
import { SvgIcon } from '@components/SvgIcon';

import { locale } from './locale';

import styles from './SideMenu.module.css';

const cn = classNames.bind(styles);

export enum AnchorEnum {
  left = 'left',
  right = 'right',
}

export interface SideMenuProps {
  anchor?: AnchorEnum;
  children: ReactNode;
  onClose: VoidFunction;
  className?: string;
  open: boolean;
  id?: string;
  component?: React.ElementType;
  role?: string;
  hideLogo?: boolean;
}

export function SideMenu({
  anchor = AnchorEnum.left,
  children,
  onClose,
  open,
  className,
  id,
  role,
  hideLogo,
  component: Component = 'div',
}: SideMenuProps) {
  return (
    <Drawer
      className={cn('side-menu', className)}
      paperClassName={cn('side-menu__paper')}
      anchor={anchor}
      open={open}
      onClose={onClose}
      id={id}
    >
      <div className={cn('side-menu__header')}>
        <button
          className={cn('side-menu__close-button', {
            'side-menu__close-button--visible': anchor === AnchorEnum.left,
          })}
          type="button"
          onClick={onClose}
          aria-label={locale.closeButtonLabel}
          tabIndex={open ? 0 : -1}
        >
          <SvgIcon className={cn('side-menu__close-icon')} icon="close" />
        </button>

        {!hideLogo && (
          <Link to="/" ariaLabel={locale.homePageLabel} tabIndex={open ? 0 : -1}>
            <MainLogo role="presentation" className={cn('side-menu__logo')} />
          </Link>
        )}

        <button
          className={cn('side-menu__close-button', 'side-menu__close-button--right', {
            'side-menu__close-button--visible': anchor === AnchorEnum.right,
          })}
          type="button"
          onClick={onClose}
          aria-label={locale.closeButtonLabel}
          tabIndex={open ? 0 : -1}
        >
          <SvgIcon icon="close" className={cn('side-menu__close-icon')} />
        </button>
      </div>

      <Component className={cn('side-menu__body')} role={role}>
        {children}
      </Component>
    </Drawer>
  );
}
