import * as React from 'react';

import LinkMaterial from '@mui/material/Link';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import { ColorEnum } from './constants';
import { LinkButtonProps } from './types';

import styles from './LinkButton.module.css';

const cn = classNames.bind(styles);

export function LinkButton({
  children,
  className,
  onClick,
  variant = 'button',
  type = 'button',
  'aria-haspopup': ariaHasPopup,
  'aria-controls': ariaControls,
  'aria-expanded': ariaExpanded,
  'aria-describedby': ariaDescribedBy,
  asRouterLink,
  replace,
  to,
  tabIndex,
  focused,
  // style props
  color = ColorEnum.PURPLE_10,
  noUnderline = false,
  reverseUnderline = false,
  bold = false,
}: LinkButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    onClick?.(event);
  };

  return (
    <LinkMaterial
      variant={variant}
      component={asRouterLink ? RouterLink : 'button'}
      className={cn('link-button', className, {
        [`link-button--${color}`]: color,
        'link-button--no-underline': noUnderline,
        'link-button--reverse-underline': reverseUnderline,
        'link-button--bold': bold,
        'link-button--focused': focused,
      })}
      color="inherit"
      onClick={onClick ? handleClick : undefined}
      type={type}
      tabIndex={tabIndex}
      aria-haspopup={ariaHasPopup}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-describedby={ariaDescribedBy}
      replace={replace}
      to={to}
    >
      {children}
    </LinkMaterial>
  );
}
