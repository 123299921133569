import React, { ReactNode } from 'react';

import classNames from 'classnames/bind';
import { SnackbarProvider as Provider } from 'notistack';

import { SvgIcon } from '@components/SvgIcon';

import { CloseButton } from '../CloseButton';

import styles from './SnackbarProvider.module.css';

const cn = classNames.bind(styles);

type SnackbarProviderProps = {
  children: ReactNode;
};

const HIDE_DURATION = 10000;

export function SnackbarProvider({ children }: SnackbarProviderProps) {
  return (
    <Provider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className={cn('snackbar')}
      classes={{
        variantSuccess: cn('snackbar--success'),
        variantError: cn('snackbar--error'),
        variantWarning: cn('snackbar--warning'),
        variantInfo: cn('snackbar--info'),
      }}
      iconVariant={{
        success: <SvgIcon icon="alertSuccess" className={cn('snackbar__icon', 'snackbar__icon--success')} />,
        error: <SvgIcon icon="alertError" className={cn('snackbar__icon', 'snackbar__icon--error')} />,
        warning: <SvgIcon icon="alertError" className={cn('snackbar__icon', 'snackbar__icon--warning')} />,
        info: <SvgIcon icon="info" className={cn('snackbar__icon', 'snackbar__icon--info')} />,
      }}
      action={CloseButton}
      autoHideDuration={HIDE_DURATION}
    >
      {children}
    </Provider>
  );
}
