import React, { useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { PATHS } from '@common/routes';
import { differenceInMinutes } from '@common/utils';
import { getLocale } from '@common/utils/locale';
import { Alert } from '@components/Alert';
import { Link, LinkButton } from '@components/Link';

import { LoginErrorCodes } from '../../constants';
import { locale } from '../../locale';
import {
  clearError,
  clearInfo,
  getBlockingTime,
  getFailedAttempts,
  getLoginError,
  getLoginInfo,
  getLoginStatus,
} from '../../store';

import styles from './Errors.module.css';

const cn = classNames.bind(styles);

const POSSIBLE_ATTEMPTS = 4;
const TIME_TO_UNBLOCK_RESET_PASSWORD = 30;

export const Errors = () => {
  const dispatch = useDispatch();
  const status = useSelector(getLoginStatus);
  const error = useSelector(getLoginError);
  const info = useSelector(getLoginInfo);
  const attempts = Number(useSelector(getFailedAttempts));
  const blockingTime = useSelector(getBlockingTime);
  const blockTimeToReset = differenceInMinutes(new Date(blockingTime as string), new Date());
  const resetUnblocked = Math.abs(blockTimeToReset) >= TIME_TO_UNBLOCK_RESET_PASSWORD;

  const handleResetError = useCallback(() => {
    dispatch(clearError());
  }, [dispatch]);

  const errorTitle =
    (attempts >= 4 && error === LoginErrorCodes.FailedLogin
      ? locale.errors[error]?.title2
      : locale.errors[error as LoginErrorCodes]?.title) ?? error;

  const errorDescription = useMemo(() => {
    if (error === LoginErrorCodes.AccountLocked) {
      return resetUnblocked
        ? locale.errors[LoginErrorCodes.AccountLocked].description
        : getLocale(locale.errors[LoginErrorCodes.AccountLocked].description2, {
            blockTimeToReset: TIME_TO_UNBLOCK_RESET_PASSWORD - Math.abs(blockTimeToReset),
          });
    }

    if (error !== LoginErrorCodes.FailedLogin || attempts >= 4) {
      return (locale.errors[error as LoginErrorCodes] as UnknownObject<string>)?.description ?? null;
    }

    return null;
  }, [attempts, blockTimeToReset, error, resetUnblocked]);

  const errorButtons = useMemo(() => {
    if (
      (error === LoginErrorCodes.FailedLogin && attempts >= POSSIBLE_ATTEMPTS) ||
      (error === LoginErrorCodes.AccountLocked && resetUnblocked)
    ) {
      return (
        <div className={cn('errors__actions')}>
          <Link className={cn('errors__action-button')} to={PATHS.PASSWORD_RECOVERY.ROOT}>
            {locale.recover_password}
          </Link>
          <LinkButton className={cn('errors__action-button')} onClick={handleResetError}>
            {locale.close}
          </LinkButton>
        </div>
      );
    }

    return null;
  }, [attempts, error, handleResetError, resetUnblocked]);

  useEffect(() => {
    if (info && status === StatusEnum.Pending) {
      dispatch(clearInfo());
    }
  }, [dispatch, status, info]);

  useEffect(() => {
    if (error && status === StatusEnum.Pending) {
      handleResetError();
    }
  }, [dispatch, status, info, handleResetError, error]);

  if (!error && !info) {
    return null;
  }

  return (
    <>
      {error && (
        <Alert className={cn('errors__alert', 'errors__alert--error')} type="error" title={errorTitle}>
          {errorDescription}
          {errorButtons}
        </Alert>
      )}
      {info && (
        <Alert className={cn('errors__alert', 'errors__alert--info')} type="info" title={locale.info[info].title} />
      )}
    </>
  );
};
