import React, { useMemo, useCallback, SyntheticEvent } from 'react';

import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { Button } from '@components/Button';
import { LineChart, LineChartProps, ChartSkeleton, DEFAULT_OPTIONS } from '@components/Charts';
import { NextTab, Tabs } from '@components/Tabs';
import { Typography } from '@components/Typography';
import { useFullContentLoaded } from '@modules/gtmFulContentLoaded';

import { WellnessTypesEnum } from '../../constants';
import { MeasurementType, ExerciseItemType } from '../../types';
import { HistoryList } from '../HistoryList';

import styles from './Dashboard.module.css';

const cn = classNames.bind(styles);

type DashboardProps<MT> = {
  heading: string;
  chartTitle: string;
  selectedMeasurement?: MT;
  measurements?: Array<MT>;
  data: LineChartProps['data'];
  charOptions?: LineChartProps['options'];
  onChangeMeasurement?: (measurement: MT) => void;
  historyItems: ExerciseItemType[];
  historyTitle: string;
  loading: boolean;
  onEditItemClick: (item: ExerciseItemType) => unknown;
  onDeleteItemClick: (item: ExerciseItemType) => unknown;
  onRecordButtonClick: VoidFunction;
  recordButtonText: string;
  type: WellnessTypesEnum;
};

export function Dashboard<MT extends MeasurementType>({
  heading,
  chartTitle,
  data,
  charOptions,
  measurements = [],
  onChangeMeasurement,
  selectedMeasurement,
  historyItems,
  historyTitle,
  loading = false,
  onEditItemClick,
  onRecordButtonClick,
  onDeleteItemClick,
  recordButtonText,
  type,
}: DashboardProps<MT>) {
  const { isMobileView, isTabletView, isDesktopView } = useScreenSize();
  const defaultOptions: LineChartProps['options'] = charOptions || DEFAULT_OPTIONS;

  useFullContentLoaded({
    loadingStarted: loading,
    loadingFinished: !loading,
  });

  const options: LineChartProps['options'] = useMemo(
    () => ({
      ...defaultOptions,
      scales: {
        ...defaultOptions.scales,
        yAxis: {
          title: {
            display: !!selectedMeasurement?.yScaleTitle,
            text: selectedMeasurement?.yScaleTitle,
          },
        },
      },
      plugins: {
        ...defaultOptions.plugins,
        tooltip: {
          ...(defaultOptions.plugins?.tooltip || {}),
          callbacks: {
            ...(defaultOptions?.plugins?.tooltip?.callbacks || {}),
            label: (tooltip) => {
              const value = (tooltip.raw as UnknownObject)?.y || (tooltip.raw as number);

              return selectedMeasurement?.shortTitle ? `${value} ${selectedMeasurement.shortTitle}` : `${value}`;
            },
          },
        },
      },
    }),
    [selectedMeasurement, defaultOptions],
  );

  const handleChangeMeasurement = useCallback(
    (_event: SyntheticEvent, measurementType: string) => {
      const measurement = measurements.find((measurement) => measurement.type === measurementType);
      measurement && onChangeMeasurement?.(measurement);
    },
    [onChangeMeasurement],
  );

  const measurementTabs = useMemo(
    () =>
      !loading &&
      measurements.length > 0 && (
        <Tabs
          className={cn('dashboard__measurements')}
          onChange={handleChangeMeasurement}
          value={selectedMeasurement?.type}
        >
          {measurements.map((measurement) => (
            <NextTab
              key={measurement.type}
              className={cn('dashboard__measurement', {
                'dashboard__measurement--selected': selectedMeasurement?.type === measurement.type,
              })}
              value={measurement.type}
              label={measurement.title}
            />
          ))}
        </Tabs>
      ),
    [loading, measurements, selectedMeasurement, handleChangeMeasurement],
  );

  return (
    <div className={cn('dashboard')}>
      {(isMobileView || isTabletView) && (
        <Typography variant="h1" className={cn('dashboard__heading')}>
          {heading}
        </Typography>
      )}

      <div className={cn('dashboard__header')}>
        {isDesktopView && (
          <Typography variant="h2" className={cn('dashboard__heading')}>
            {heading}
          </Typography>
        )}

        {!isDesktopView && measurementTabs}

        {!isMobileView && (
          <Button variant="contained" color="warning" onClick={onRecordButtonClick} disabled={loading}>
            {recordButtonText}
          </Button>
        )}
      </div>

      {isDesktopView && measurementTabs}

      <div className={cn('dashboard__chart-block')}>
        {chartTitle && (
          <Typography variant="h4" className={cn('dashboard__title')} component={!isTabletView ? 'h3' : 'h2'}>
            {chartTitle}
          </Typography>
        )}

        {loading ? (
          <ChartSkeleton />
        ) : (
          <LineChart
            data={data}
            className={cn('dashboard__chart')}
            options={options}
            ariaLabel={chartTitle}
            shouldFormatLabel
          />
        )}
      </div>

      {isMobileView && (
        <Button
          className={cn('dashboard__button')}
          variant="contained"
          size="small"
          color="warning"
          onClick={onRecordButtonClick}
          disabled={loading}
        >
          {recordButtonText}
        </Button>
      )}

      <HistoryList
        items={historyItems}
        title={historyTitle}
        onEditItemClick={onEditItemClick}
        loading={loading}
        type={type}
        onDeleteItemClick={onDeleteItemClick}
        isTablet={isMobileView || isTabletView}
      />
    </div>
  );
}
