import React, { forwardRef } from 'react';

import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  TimeScale,
  Plugin,
  ScatterDataPoint,
} from 'chart.js';
import cloneDeep from 'lodash.clonedeep';
import { ChartProps, Line } from 'react-chartjs-2';

import { COLORS } from '@common/constants/colors';

import { formatLineChartAriaLabel } from './utils';

const PaddingWorkaroundPlugin = {
  id: 'customSpacingLegend',
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeInit(chart: any) {
    const originalFit = chart.legend.fit;
    // eslint-disable-next-line no-param-reassign
    chart.legend.fit = function fit() {
      originalFit.bind(chart.legend)();
      if (this) {
        this.height += 15;
      }
    };
  },
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  PaddingWorkaroundPlugin,
);

const AXIS_VALUE_DIFFERENCE_PERCENTAGE = 20;
export const DEFAULT_OPTIONS = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    yAxis: {},
    xAxis: {
      grid: {
        drawOnChartArea: false,
      },
      ticks: {
        beginAtZero: true,
        showLabelBackdrop: true,
        color: COLORS.GRAY_20,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      backgroundColor: COLORS.WHITE,
      borderColor: COLORS.ORANGE_0,
      borderWidth: 1,
      titleColor: COLORS.GRAY_0,
      titleFont: { size: 12 },
      bodyFont: { size: 16 },
      bodyColor: COLORS.GRAY_25,
      displayColors: false,
      padding: 10,
    },
  },
  elements: {
    line: {
      borderWidth: 2,
      borderColor: COLORS.ORANGE_0,
      backgroundColor: COLORS.ORANGE_0,
    },
    point: {
      radius: 4,
      hitRadius: 5,
      backgroundColor: COLORS.ORANGE_0,
    },
  },
};

type LineChartType = ChartProps<'line'>;

const defaultPlugin: Plugin<'line', UnknownObject<string>> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, args, options) => {
    const { ctx } = chart;
    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = options.color || 'transparent';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const updateOptions = (options: NonNullable<LineChartType['options']>, data: LineChartType['data']) => {
  const dataArray = data.datasets[0].data;
  const firstItemValue = dataArray[0];
  const newOptions: LineChartType['options'] = cloneDeep(options);
  let valuesArray: number[] = [];

  if (typeof firstItemValue === 'number') {
    valuesArray = dataArray as number[];
  } else {
    valuesArray = dataArray.map((item) => (item as ScatterDataPoint)?.y) as number[];
  }
  const maxYValue = Math.max(...valuesArray);
  const minYValue = Math.min(...valuesArray);

  const modifier = (maxYValue * AXIS_VALUE_DIFFERENCE_PERCENTAGE) / 100;

  if (!newOptions.scales) {
    newOptions.scales = {};
  }

  if (!newOptions.scales.yAxis) {
    newOptions.scales.yAxis = {};
  }

  (newOptions.scales.yAxis as { suggestedMax: number }).suggestedMax = maxYValue + modifier;
  (newOptions.scales.yAxis as { suggestedMin: number }).suggestedMin = minYValue - modifier;

  return newOptions;
};

export type LineChartProps = {
  className?: string;
  ariaLabel?: LineChartType['aria-label'];
  shouldFormatLabel?: boolean;
  width?: string;
  height?: string;
  overridePlugin?: Plugin;
} & Pick<LineChartType, 'options' | 'data'>;

export function LineChartComponent(
  {
    options = DEFAULT_OPTIONS,
    data,
    className,
    ariaLabel,
    shouldFormatLabel,
    width,
    height,
    overridePlugin,
  }: LineChartProps,
  ref: Parameters<typeof Line>[0]['ref'],
) {
  const updatedOptions = updateOptions(options, data);
  const lineChartAriaLabel = shouldFormatLabel ? formatLineChartAriaLabel(data, updatedOptions, ariaLabel) : ariaLabel;

  const plugin = overridePlugin || defaultPlugin;

  return (
    <Line
      options={updatedOptions}
      data={data}
      className={className}
      plugins={[plugin]}
      aria-label={lineChartAriaLabel}
      width={width}
      height={height}
      ref={ref}
    />
  );
}

export const LineChart = forwardRef(LineChartComponent);
