import React, { useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { SignModal } from '@components/SignModals';
import { logout } from '@modules/Login';
import { acceptWaiverAcknowledgment, GlobalWeaverPurposes } from '@modules/globalWeaver';
import { getWaiverAcknowledgments } from '@modules/globalWeaver/store/actions';
import { currentEligibilitySelector } from '@modules/userProfileData';

import { locale } from './locale';
import { useTermsOfUseAndPrivacyPolicy } from './useTermsOfUseAndPrivacyPolicy';

export const TermsOfUseAndPrivacyPolicy = () => {
  const dispatch = useDispatch();
  const currentEligibility = useSelector(currentEligibilitySelector);
  const { required, waiverIds, body, title } = useTermsOfUseAndPrivacyPolicy();

  const onClose = useCallback(() => {
    dispatch(logout({ inactivityLogout: false }));
  }, [dispatch]);

  const onSubmit = useCallback(() => {
    if (currentEligibility?.id) {
      if (waiverIds.termsOfUse)
        {dispatch(
          acceptWaiverAcknowledgment({ purpose: GlobalWeaverPurposes.TermsOfUse, waiverId: waiverIds.termsOfUse }),
        );}
      if (waiverIds.privacyPolicy)
        {dispatch(
          acceptWaiverAcknowledgment({
            purpose: GlobalWeaverPurposes.PrivacyPolicy,
            waiverId: waiverIds.privacyPolicy,
          }),
        );}
    }
  }, [currentEligibility?.id, waiverIds, dispatch]);

  useEffect(() => {
    if (currentEligibility) {
      dispatch(getWaiverAcknowledgments([GlobalWeaverPurposes.TermsOfUse, GlobalWeaverPurposes.PrivacyPolicy]));
    }
  }, [currentEligibility, dispatch]);

  return (
    <SignModal
      open={required}
      onClose={onClose}
      onSubmit={onSubmit}
      title={title}
      body={body}
      cancelButtonTitle={locale.cancel}
      signButtonTitle={locale.accept}
    />
  );
};
