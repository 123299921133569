import { createSelector, createStructuredSelector } from 'reselect';

import { ClinicType, NetworkForAllMembersType, ProviderType } from '../types';

export const getNetworkForAllMembersDrySelector = (state: RootState) => state.networks.networkForAllMembers;
export const getNetworkStatusSelector = (state: RootState) => state.networks.networkForAllMembersStatus;
export const getNetworkProvidersSelector = (state: RootState) => state.networks.networkForAllMembers.providers;
export const getHasAnywhereNetworkSelector = (state: RootState) =>
  state.networks.networkForAllMembers.clinics?.some((clinic) => clinic.clinic_grouping === 'Anywhere');

const getCurrentUserIdSelector = (state: RootState) => state.userProfile.userProfileData.id;

export const networksSelector = createStructuredSelector({
  networkForAllMembersStatus: getNetworkStatusSelector,
  userId: getCurrentUserIdSelector,
});

export const getNetworkForAllMembersSelector = createSelector(
  getNetworkForAllMembersDrySelector,
  ({ patients, clinics, providers }): NetworkForAllMembersType => ({
    patients: patients?.map((patient) => ({
      ...patient,
      clinic_groupings: patient.clinic_groupings.map((clinicGroup) => ({
        ...clinicGroup,
        clinics: clinicGroup.clinics
          .map((clinicGroupingClinic) => clinics.find((clinic) => clinic.id === clinicGroupingClinic.id))
          .filter(Boolean) as ClinicType[],
      })),
      providers: patient.providers
        .map((providerId) => providers.find((provider) => provider.id === providerId))
        .filter(Boolean) as ProviderType[],
    })),
  }),
);
