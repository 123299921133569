import React, { useCallback, useState } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { useIgnoreEffectDeps } from '@common/hooks/useIgnoreEffectDeps';
import { datePlusTime, formatDateForServer, hmsToSeconds } from '@common/utils';
import { Button } from '@components/Button';
import { FormButtons } from '@components/FormButtons';
import { RecordActivityModal } from '@components/RecordActivityModal';
import { Typography } from '@components/Typography';

import { WellnessAdditionalTypesEnum, WellnessTypesEnum } from '../../constants';
import {
  fitnessAndStepsSelector,
  fitnessRecordActivitySelector,
  hideEditModal,
  SaveExerciseRequestType,
  SaveStepsRequestType,
  saveLogItem,
  stepsRecordActivitySelector,
} from '../../store';
import { useResetEntryCount } from '../../useReserEntryCount';
import { FitnessForm } from '../FitnessDashboard/components/FitnessForm';
import { FormValuesType as FitnessDashboardFormValuesType } from '../FitnessDashboard/types';
import { StepsForm } from '../StepsDashboard/components/StepsForm';
import { FormValuesType as StepsDashboardType } from '../StepsDashboard/types';
import { locale } from './locale';

import styles from './FitnessAndStepsActivityModal.module.css';

const cn = classNames.bind(styles);

type Props = {
  headerClassName?: string;
};

const TITLE_ID = 'record_activity_modal_title';

export function FitnessAndStepsActivityModal({ headerClassName }: Props) {
  const [selectedType, setSelectedType] = useState<WellnessTypesEnum | null>(null);
  const {
    exerciseTypes,
    exerciseTypesStatus,
    saveFormStatus: fitnessSaveFormStatus,
  } = useSelector(fitnessRecordActivitySelector);
  const { saveFormStatus: stepsSaveFormStatus } = useSelector(stepsRecordActivitySelector);
  const { editModalVisible } = useSelector(fitnessAndStepsSelector);
  const saveFormStatus = selectedType === WellnessTypesEnum.Fitness ? fitnessSaveFormStatus : stepsSaveFormStatus;
  const dispatch = useDispatch();
  const [entryCount, setEntryCount] = useState(0);

  const handleClose = useCallback(() => {
    dispatch(hideEditModal({ type: WellnessAdditionalTypesEnum.FitnessAndSteps }));
    dispatch(hideEditModal({ type: WellnessTypesEnum.Fitness }));
    dispatch(hideEditModal({ type: WellnessTypesEnum.Steps }));
    setSelectedType(null);
  }, [dispatch]);

  const handleSaveFitness = useCallback(
    (formValues: FitnessDashboardFormValuesType, recordAnotherEntry = false) => {
      let requestData: SaveExerciseRequestType['data'] = {
        id: formValues.id,
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
        type: formValues.type?.type as string,
      };

      const duration = hmsToSeconds(formValues);
      const distance = Number(formValues.distance);

      if (formValues.type?.label || formValues.name) {
        requestData = {
          ...requestData,
          name: formValues.name || formValues.type?.label,
        };
      }

      if (distance) {
        requestData = {
          ...requestData,
          distance,
        };
      }

      if (duration) {
        requestData = {
          ...requestData,
          duration,
        };
      }

      dispatch(
        saveLogItem({
          data: requestData,
          type: WellnessTypesEnum.Fitness,
          additionalType: WellnessAdditionalTypesEnum.FitnessAndSteps,
          recordAnotherEntry,
          entryCount,
        }),
      );
      setEntryCount(entryCount + 1);
    },
    [entryCount, dispatch],
  );

  const handleSaveSteps = useCallback(
    (formValues: StepsDashboardType, recordAnotherEntry = false) => {
      const requestData: SaveStepsRequestType['data'] = {
        id: formValues.id,
        steps: Number(formValues.steps),
        recorded_at: formatDateForServer(datePlusTime(formValues.date, formValues.time)),
      };

      dispatch(
        saveLogItem({
          data: requestData,
          type: WellnessTypesEnum.Steps,
          recordAnotherEntry,
          entryCount,
          additionalType: WellnessAdditionalTypesEnum.FitnessAndSteps,
        }),
      );
      setEntryCount(entryCount + 1);
    },
    [dispatch, entryCount],
  );

  const handleSelectFitness = useCallback(() => {
    setSelectedType(WellnessTypesEnum.Fitness);
  }, []);

  const handleSelectSteps = useCallback(() => {
    setSelectedType(WellnessTypesEnum.Steps);
  }, []);

  useIgnoreEffectDeps(
    () => () => {
      handleClose();
    },
    [],
  );

  useResetEntryCount(editModalVisible, setEntryCount);

  return (
    <RecordActivityModal
      open={editModalVisible}
      onClose={handleClose}
      className={cn('fitness-and-steps')}
      modalContentClassName={cn('fitness-and-steps__content')}
    >
      <Typography variant="h2" className={cn('fitness-and-steps__header', headerClassName)} id={TITLE_ID}>
        {locale.header}
      </Typography>

      <div className={cn('fitness-and-steps__sub-header')}>
        <span className={cn('fitness-and-steps__asterisk')}>*</span> {locale.sub_header}
      </div>

      <div className={cn('fitness-and-steps__buttons')}>
        <Button
          variant={selectedType === WellnessTypesEnum.Steps ? 'contained' : 'outlined'}
          onClick={handleSelectSteps}
          className={cn('fitness-and-steps__action')}
          startIcon="shoe"
        >
          {locale.steps}
        </Button>

        <Button
          variant={selectedType === WellnessTypesEnum.Fitness ? 'contained' : 'outlined'}
          onClick={handleSelectFitness}
          className={cn('fitness-and-steps__action')}
          startIcon="sport"
        >
          {locale.fitness}
        </Button>
      </div>

      {selectedType === WellnessTypesEnum.Fitness && (
        <FitnessForm
          showTitle={false}
          exerciseTypes={exerciseTypes}
          onClose={handleClose}
          onSave={handleSaveFitness}
          saveFormStatus={saveFormStatus}
          exerciseTypesStatus={exerciseTypesStatus}
          className={cn('fitness-and-steps__form')}
        />
      )}

      {selectedType === WellnessTypesEnum.Steps && (
        <StepsForm
          showTitle={false}
          onSave={handleSaveSteps}
          onClose={handleClose}
          saveFormStatus={saveFormStatus}
          className={cn('fitness-and-steps__form')}
        />
      )}

      {!selectedType && <FormButtons className={cn('fitness-and-steps__form-buttons')} onClose={handleClose} />}
    </RecordActivityModal>
  );
}
