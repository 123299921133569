import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProviderRouteStateType } from '@modules/ProvidersCommon';
import { resetStoreActions } from '@modules/reset';

import { ScheduleAppointmentDataType } from '../../../types';
import { ModalsEnum } from '../constants';
import { AppointmentsState, SetScheduleAppointmentDataType, SetModalStatePayloadType } from './types';

const initialState: AppointmentsState = {
  scheduleAppointmentData: null,

  visitReasonSearch: '',

  modalsState: {
    [ModalsEnum.WorkRelatedInjury]: false,
    [ModalsEnum.BehavioralReason]: false,
    [ModalsEnum.EmergencyReasons]: false,
  },

  schedulingForProvider: null,
};

export const scheduleAppointmentSlice = createSlice({
  name: 'scheduleNewAppointment',
  initialState,
  reducers: {
    setDataState(state, action: PayloadAction<SetScheduleAppointmentDataType>) {
      state.scheduleAppointmentData = {
        ...(state.scheduleAppointmentData ? state.scheduleAppointmentData : ({} as ScheduleAppointmentDataType)),
        ...action.payload,
      };
    },

    setSearch(state, action: PayloadAction<string>) {
      state.visitReasonSearch = action.payload;
    },

    resetData(state) {
      state.scheduleAppointmentData = initialState.scheduleAppointmentData;
    },

    setModalState(state, action: PayloadAction<SetModalStatePayloadType>) {
      state.modalsState = {
        ...state.modalsState,
        ...action.payload,
      };
    },

    setSchedulingForProvider(state, action: PayloadAction<ProviderRouteStateType | null>) {
      state.schedulingForProvider = action.payload;
    },

    resetAll() {
      return initialState;
    },

    ...resetStoreActions(() => initialState),
  },
});
export const { setDataState, resetData, setSearch, setModalState, resetAll, setSchedulingForProvider } =
  scheduleAppointmentSlice.actions;
export const scheduleNewAppointmentReducer = scheduleAppointmentSlice.reducer;
