import React from 'react';

import classNames from 'classnames/bind';

import { SideBarWidget } from '@components/SideBarWidget';

import { ApplicationsButtons } from './components/ApplicationsButtons';
import { Description } from './components/Description';
import { Form } from './components/Form';

import styles from './Login.module.css';

const cn = classNames.bind(styles);

type Props = {
  className?: string;
};

export const Login = ({ className }: Props) => (
  <SideBarWidget className={cn('login', className)}>
    <Description />
    <Form />
    <ApplicationsButtons className={cn('applications-buttons')} />
  </SideBarWidget>
);
