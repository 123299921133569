import React, { useMemo } from 'react';

import classNames from 'classnames/bind';
import { Control } from 'react-hook-form';

import { Button } from '@components/Button';
import { Modal } from '@components/Modal';
import { Skeleton } from '@components/Skeleton';

import { SignModalContent } from './components/SignModalContent';

import styles from './SignModal.module.css';

const cn = classNames.bind(styles);

type Props = {
  open: boolean;
  onClose: () => void;
  onSubmit: () => void;
  getData?: () => void;
  title?: Nullable<string>;
  body: Nullable<string>;
  loading?: boolean;
  cancelButtonTitle: string;
  signButtonTitle: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any>;
  agreementTitle?: string;
  agreementFieldName?: string;
  className?: string;
};

export const SignModal = ({
  open,
  control,
  agreementTitle,
  agreementFieldName,
  title,
  body,
  cancelButtonTitle,
  signButtonTitle,
  loading,
  disabled,
  getData,
  onClose,
  onSubmit,
  className,
}: Props) => {
  const renderActions = useMemo(
    () => (
      <div className={cn('actions')}>
        <Button className={cn('action-button')} onClick={onClose} variant="outlined" size="small">
          {cancelButtonTitle}
        </Button>
        <Button
          className={cn('action-button')}
          disabled={disabled}
          onClick={onSubmit}
          type="submit"
          variant="contained"
          size="small"
        >
          {signButtonTitle}
        </Button>
      </div>
    ),
    [cancelButtonTitle, disabled, onClose, onSubmit, signButtonTitle],
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      showCrossButton={false}
      className={cn('root', className)}
      title={title ?? <Skeleton />}
      actions={renderActions}
      disableEscapeKeyDown
      ignoreBackdropClick
    >
      <SignModalContent
        body={body}
        control={control}
        onSubmit={onSubmit}
        agreementTitle={agreementTitle}
        agreementFieldName={agreementFieldName}
        loading={loading}
        getData={getData}
      />
    </Modal>
  );
};
