export enum COLORS {
  GRAY_0 = '#6c737a',
  GRAY_20 = '#3e4246',
  GRAY_25 = '#323639',
  ORANGE_0 = '#db2c00',
  WHITE = '#ffffff',
  PURPLE_10 = '#963695',
  MINT_15 = '#1a8262',
  ORANGE_20 = '#db2c00',
  BLUE_0 = '#1c738f',
}
