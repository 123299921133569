import React from 'react';

import classNames from 'classnames/bind';

import { browserTimeZone, formatInTimeZone } from '@common/utils';
import { getLocale } from '@common/utils/locale';
import { MenuItem } from '@components/Menu';
import { SvgIcon } from '@components/SvgIcon';
import { useGetAthenaValues } from '@modules/Messages/hooks/useGetAthenaValues';

import { locale } from '../ConversationsList/locale';

import styles from './ConversationListItem.module.css';

type Props = {
  id: number;
  sender: string;
  subject: string;
  recentMessageAt?: string;
  recentMessageHasAttachments: boolean;
  hasUnreadMessage: boolean;
  athenaProviderId: Nullable<number>;
  athenaDepartmentId: Nullable<number>;
  isDraft: boolean;
  conversationId: number;
  handleMessageClick: VoidFunction;
};

const cn = classNames.bind(styles);

export const ConversationListItem = ({
  id,
  sender,
  subject,
  recentMessageAt,
  recentMessageHasAttachments,
  hasUnreadMessage,
  athenaProviderId,
  athenaDepartmentId,
  conversationId,
  isDraft,
  handleMessageClick,
}: Props) => {
  const { athenaProviderName, athenaDepartmentName } = useGetAthenaValues(athenaProviderId, athenaDepartmentId);

  const senderName = athenaProviderName || athenaDepartmentName || sender || locale.member_relation_team;
  const recentMessageAtLabel =
    recentMessageAt && browserTimeZone
      ? getLocale(locale[isDraft ? 'saved_on' : 'sent'], {
          date: formatInTimeZone(recentMessageAt, browserTimeZone, 'MMMM dd, yyyy'),
        })
      : '';

  return (
    <MenuItem
      className={cn('item', {
        'item--active': conversationId === id,
      })}
      key={id}
      onClick={handleMessageClick}
      aria-label={getLocale(locale.conversation_list_aria, {
        sender: senderName,
        recentMessageAt: recentMessageAtLabel,
        subject,
      })}
    >
      <div className={cn('container')}>
        <div
          className={cn('sender-name', {
            'sender-name--unread': hasUnreadMessage,
            'sender-name--active': conversationId === id,
          })}
        >
          <span>{senderName}</span>
        </div>
        <div
          className={cn('recent-date-container', {
            'recent-date-container--unread': hasUnreadMessage,
          })}
        >
          {recentMessageHasAttachments && <SvgIcon className={cn('attachments')} icon="attachments" />}
          {recentMessageAt && browserTimeZone && (
            <div className={cn('recent-date')}>{formatInTimeZone(recentMessageAt, browserTimeZone, 'MM/dd/yyyy')}</div>
          )}
        </div>
      </div>
      <div className={cn('subject-name')}>{subject}</div>
      {recentMessageHasAttachments && <div className="sr-only">{locale.has_attachments}</div>}
    </MenuItem>
  );
};
