export type EligibilitiesType = {
  id: number;
  is_supplemental_waiver_required: boolean;
  term_of_use_required: boolean;
  employer: {
    id: number;
    name: string;
    questionnaire_to_enable: Nullable<string>;
  };
  eligibility_type_id: number;
  eligibility_type_name: string;
};

export type UserProfileType = {
  id: number;
  user_name?: string;
  email?: Nullable<string>;
  first_name: string;
  last_name: string;
  middle_name: Nullable<string>;
  preferred_first_name: Nullable<string>;
  username: string;
  born_on: Nullable<string>;
  home_phone_number: Nullable<string>;
  cell_phone_number: Nullable<string>;
  social_security_number: string;
  gender: string;
  pronouns: Nullable<string>;
  registration_status?: string;
  preferred_order_of_contact: string;
  wellness_id: string;
  prefix: Nullable<string>;
  suffix: Nullable<string>;
  name_prefix?: Nullable<string>;
  name_suffix?: Nullable<string>;
  primary_language: string;
  work_phone_number?: Nullable<string>;
  work_email?: Nullable<string>;
  emergency_contact_first_name?: Nullable<string>;
  emergency_contact_last_name?: Nullable<string>;
  emergency_contact_phone_number?: Nullable<string>;
  uscis_number: string;
  individual_taxpayer_identification_number: string;
  private_insurance_carrier_name: string;
  private_insurance_group_id_number: Nullable<string>;
  private_insurance_member_id_number: Nullable<string>;
  private_insurance_rx_bin: string;
  private_insurance_rx_pcn: string;
  system_display_name: string;
  nickname: string;
  health_coach_form_version: string;
  refused_to_give_email: boolean;
  consent_to_call: boolean;
  consent_to_email: boolean;
  consent_to_text: boolean;
  time_zone?: Nullable<string>;
  iana_time_zone?: Nullable<string>;
  is_restricted?: Nullable<boolean>;
  state_of_indiana_clinic_access?: Nullable<boolean>;
  state_of_indiana_wellness_only?: Nullable<boolean>;
  home_state?: Nullable<string>;
};

export type UserRoleType = {
  employer: string | null;
  health_center: string | null;
  name: string;
};

export enum SamlNavigationType {
  Failed = 'failed',
  AutoLogin = 'autoLogin',
  NoMatchFound = 'noMatchFound',
  RegisterUser = 'registerUser',
  VerifyUser = 'verifyUser',
}

export type UserResponseType = {
  is_on_boarding_passed?: boolean;
  user: UserProfileType;
  user_auth: {
    current_eligibility: EligibilitiesType;
    enabled_features: Array<string>;
    is_active_incentive_program: boolean;
    is_previewable_incentive_program: boolean;
    is_supplemental_waiver_required: boolean;
    terms_of_use_required: boolean;
    hcbb_url?: string | null;
    roles: Array<UserRoleType>;
    impersonator: {
      user_name: string;
      time_zone?: Nullable<string>;
      iana_time_zone?: Nullable<string>;
    };
    save_rx_worthy?: boolean;
    hide_challenges?: boolean;
    hide_incentives?: boolean;
  };
};

export type UserAuthResponseType = {
  // TODO: describe other fields
  [key: string]: unknown;
  user: {
    [key: string]: unknown;
    cell_phone?: string;
  };
};

export type Signed = {
  [key: string | number]: boolean;
};
