import React from 'react';

import classNames from 'classnames/bind';

import { IconType, SvgIcon } from '@components/SvgIcon';

import styles from './ActionItemInner.module.css';

const cn = classNames.bind(styles);

type Props = {
  title: string;
  icon: IconType;
};

export const ActionItemInner = ({ title, icon }: Props) => (
  <span className={cn('root')}>
    <SvgIcon className={cn('icon')} icon={icon} />
    {title}
  </span>
);
