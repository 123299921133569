import isNil from 'lodash.isnil';

import { isAfter, isBefore, isSameDay, parseISOLocal } from '@common/utils/dateTimeUtil';
import { ClinicType as NetworksClinicType, NetworkPatientType } from '@modules/Networks';

import {
  ANNUAL_PHYSICAL_ID,
  ANNUAL_PHYSICAL_PAP_ID,
  COMPREHENSIVE_HEALTH_REVIEW_ID,
  LAB_WORK_ID,
  ANYWHERE_NETWORK,
  VisitTypesEnum,
} from './constants';
import {
  ClinicGroupingForSelectType,
  ClinicType,
  DateIntervalType,
  OpenAppointmentType,
  SoonestAvailabilityType,
} from './types';

export const getClinicIds = (clinics: NetworksClinicType[] | undefined) =>
  clinics?.reduce?.((acc, clinic) => [...acc, clinic.id], [] as number[]) || ([] as number[]);

export const getClinicEndDates = (clinics: NetworksClinicType[] | undefined) =>
  clinics?.reduce?.((acc, clinic) => [...acc, clinic.self_schedule_end_date], [] as string[]) || ([] as string[]);

export const getNetworksByFilter = <T extends { clinic_grouping: string }>(
  allNetworks: T[],
  networksFilter: string[],
) => allNetworks.filter((network) => networksFilter.includes(network.clinic_grouping));

export const getClinicIdsAndEndDates = (
  allNetworks: ClinicGroupingForSelectType[] = [],
  networksFilter: string[] = [],
) => {
  const networks = getNetworksByFilter(allNetworks, networksFilter);
  const clinics = networks.reduce<NetworksClinicType[]>((acc, network) => [...acc, ...(network?.clinics || [])], []);
  const clinicIds = getClinicIds(clinics);
  const clinicEndDates = getClinicEndDates(clinics);

  return { clinicIds, clinicEndDates };
};

export function addZeroBeforeTime(n: number) {
  return (`${n}`.length === 1 ? '0' : '') + n;
}

export const soonestAvailabilityFilter = (
  appointments: OpenAppointmentType[],
  soonestAvailability: SoonestAvailabilityType[],
) =>
  appointments.filter((appointment) => {
    if (appointment.start_time_in_time_zone || appointment.start_time) {
      const appointmentDate = new Date(appointment.start_time_in_time_zone || appointment.start_time);
      const appointmentTime = `${addZeroBeforeTime(appointmentDate.getHours())}:${addZeroBeforeTime(
        appointmentDate.getMinutes(),
      )}`;

      return soonestAvailability.some(({ start, end }) => appointmentTime >= start && appointmentTime <= end);
    }

    return true;
  });

export const isDayLoaded = (day: Date, activeDates?: DateIntervalType[]) => {
  if (!activeDates) {
    return false;
  }

  return activeDates.some(
    ({ startDate, endDate }) =>
      (startDate && isAfter(day, new Date(startDate)) && endDate && isBefore(day, new Date(endDate))) ||
      isSameDay(day, new Date(startDate)) ||
      isSameDay(day, new Date(endDate)),
  );
};

export const isDayUnAvailable = (day: Date, availableDates?: string[]) => {
  if (!availableDates?.length) {
    return true;
  }

  return !availableDates.some((date) => isSameDay(day, parseISOLocal(date)));
};

export const isLabWork = (visitCategoryId?: Nullable<number>) => visitCategoryId === LAB_WORK_ID;

export const isScheduleLabWork = (visitReasonId?: number) =>
  [ANNUAL_PHYSICAL_ID, ANNUAL_PHYSICAL_PAP_ID].includes(visitReasonId as number);

export const isScheduleChrWork = (visitReasonId?: number) => COMPREHENSIVE_HEALTH_REVIEW_ID === visitReasonId;

const LOCAL_DISTANCE_IN_MILES = 75;

const isClinicLocal = (clinic: ClinicType) => Number(clinic.distance_in_miles) <= LOCAL_DISTANCE_IN_MILES;

export const getLocalClinics = (clinics: ClinicType[]) => clinics.filter(isClinicLocal);

export const getNonLocalClinics = (clinics: ClinicType[]) => clinics.filter((clinic) => !isClinicLocal(clinic));

export const isAllClinicsLocal = (clinics: ClinicType[]) => clinics.length && clinics.every(isClinicLocal);

export const convertToCollapsedVisitType = (visitType: VisitTypesEnum): VisitTypesEnum =>
  visitType === VisitTypesEnum.InPerson ? VisitTypesEnum.InPerson : VisitTypesEnum.Virtual;

export const isVisitTypeInPerson = (visitTypeId: Nullable<VisitTypesEnum>): boolean =>
  visitTypeId === VisitTypesEnum.InPerson;
export const isVisitTypeVirtual = (visitTypeId: Nullable<VisitTypesEnum>): boolean =>
  [VisitTypesEnum.Virtual, VisitTypesEnum.Telephonic].includes(visitTypeId as VisitTypesEnum);

export const getNetworkClinicGroupings = (network?: NetworkPatientType): ClinicGroupingForSelectType[] =>
  network?.clinic_groupings?.map((clinicGrouping) => ({
    ...clinicGrouping,
    id: clinicGrouping.clinic_grouping,
    name: clinicGrouping.clinic_grouping,
  })) || [];

export const filterNetworkByVisitType = <T extends { is_virtual: boolean }>(
  clinicGroupings: T[],
  isVisitTypeInPerson: boolean,
) => {
  if (isVisitTypeInPerson) {
    return clinicGroupings.filter((clinicGrouping) => !clinicGrouping.is_virtual);
  }

  return clinicGroupings;
};

export const getNetworksFilter = (
  allNetworks: { clinic_grouping: string; clinics: { id: number }[] }[],
  visitType: Nullable<VisitTypesEnum>,
  providersLicensedToState: { departments_mapping: number[] }[],
): string[] => {
  const isInPerson = isVisitTypeInPerson(visitType);
  const anywhereNetwork = allNetworks.find((network) => network.clinic_grouping === ANYWHERE_NETWORK);
  const anywhereNetworkId = anywhereNetwork?.clinic_grouping;
  const networksWithoutAnywhere = allNetworks.filter(
    (network) => network.clinic_grouping && network.clinic_grouping !== ANYWHERE_NETWORK,
  );
  const firstNetworkId = networksWithoutAnywhere[0]?.clinic_grouping;
  if (isInPerson) {
    return [firstNetworkId].filter(Boolean) as string[];
  }

  const firstVirtualNetworkId = networksWithoutAnywhere.find((network) =>
    network.clinics.some((clinic) =>
      providersLicensedToState.some((provider) => provider.departments_mapping.includes(clinic.id)),
    ),
  )?.clinic_grouping;

  return [anywhereNetworkId, firstVirtualNetworkId].filter(Boolean) as string[];
};

export const compareByNames = <T extends { display_name?: Nullable<string>; name?: Nullable<string> }>(
  a: T,
  b: T,
): number => {
  const aName = a.display_name || a.name || '';
  const bName = b.display_name || b.name;

  return isNil(bName) ? -1 : aName.localeCompare(bName);
};
