import React from 'react';

import classNames from 'classnames/bind';

import { Button } from '@components/Button';

import { locale } from './locale';

import styles from './FormButtons.module.css';

const cn = classNames.bind(styles);

type FormButtonsProps = {
  className?: string;
  onClose?: VoidFunction;
  onDelete?: VoidFunction;
  isLoading?: boolean;
  deleteTitle?: string;
  cancelTitle?: string;
  repeatFormTitle?: string;
  submitTitle?: string;
  onSubmitRepeatForm?: (e?: React.BaseSyntheticEvent) => Promise<void>;
  onSubmit?: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

export function FormButtons({
  onClose,
  isLoading,
  deleteTitle,
  cancelTitle,
  repeatFormTitle,
  submitTitle,
  onSubmitRepeatForm,
  onSubmit,
  onDelete,
  className,
}: FormButtonsProps) {
  return (
    <div className={cn('form-buttons', className)}>
      {(!!onDelete || !!onClose) && (
        <div className={cn('form-buttons__line')}>
          {!!onDelete && (
            <Button variant="outlined" onClick={onDelete} className={cn('form-buttons__action')} disabled={isLoading}>
              {deleteTitle ?? locale.delete}
            </Button>
          )}

          {!!onClose && (
            <Button variant="outlined" onClick={onClose} className={cn('form-buttons__action')} disabled={isLoading}>
              {cancelTitle ?? locale.cancel}
            </Button>
          )}
        </div>
      )}

      {!!onSubmitRepeatForm && (
        <div className={cn('form-buttons__line')}>
          <Button
            variant="outlined"
            type="submit"
            className={cn('form-buttons__action')}
            disabled={isLoading}
            onClick={onSubmitRepeatForm}
          >
            {repeatFormTitle ?? locale.repeat_form_submit}
          </Button>
        </div>
      )}

      {!!onSubmit && (
        <div className={cn('form-buttons__line')}>
          <Button
            variant="contained"
            type="submit"
            className={cn('form-buttons__action')}
            disabled={isLoading}
            onClick={onSubmit}
          >
            {submitTitle ?? locale.submit}
          </Button>
        </div>
      )}
    </div>
  );
}
