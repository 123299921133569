import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusEnum } from '@common/constants';
import { resetStoreActionsUsingBuilder } from '@modules/reset';

import { GlobalWeaverPurposes } from '../constants';
import { acceptWaiverAcknowledgment, getWaiverAcknowledgment, getWaiver, getWaiverAcknowledgments } from './actions';
import { GlobalWeaverState, Data } from './types';

const initialState = Object.values(GlobalWeaverPurposes).reduce((acc, purpose) => {
  acc[purpose] = {
    status: StatusEnum.Uninitialized,
    signed: false,
    data: null,
    forceRun: false,
  };

  return acc;
}, {} as GlobalWeaverState);

const globalWaiverSlice = createSlice({
  name: 'globalWaiver',
  initialState,
  reducers: {
    forceRun: (state, action: PayloadAction<GlobalWeaverPurposes>) => {
      state[action.payload].forceRun = true;
      state[action.payload].signed = false;
    },
    forceStop: (state, action: PayloadAction<GlobalWeaverPurposes>) => {
      state[action.payload].forceRun = false;
      state[action.payload].signed = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWaiverAcknowledgment.pending, (state, action) => {
      state[action.meta.arg].status = StatusEnum.Pending;
    });
    builder.addCase(getWaiverAcknowledgment.fulfilled, (state, action) => {
      state[action.meta.arg].status = StatusEnum.Fulfilled;
      state[action.meta.arg].data = action.payload;
    });
    builder.addCase(getWaiverAcknowledgments.pending, (state, action) => {
      action.meta.arg.forEach((purpose: GlobalWeaverPurposes) => {
        state[purpose].status = StatusEnum.Pending;
      });
    });
    builder.addCase(getWaiverAcknowledgments.fulfilled, (state, action) => {
      action.meta.arg.forEach((purpose: GlobalWeaverPurposes, idx: number) => {
        state[purpose].status = StatusEnum.Fulfilled;
        state[purpose].data = (action.payload as Array<Nullable<Data>>)[idx];
      });
    });
    builder.addCase(getWaiverAcknowledgment.rejected, (state, action) => {
      state[action.meta.arg].status = StatusEnum.Rejected;
    });
    builder.addCase(getWaiver.fulfilled, (state, action) => {
      const dataByPurpose = state[action.meta.arg].data;

      if (dataByPurpose) {
        dataByPurpose.supplemental_waiver = action.payload;
      } else {
        state[action.meta.arg].data = {
          supplemental_waiver: action.payload,
        };
      }
    });
    builder.addCase(acceptWaiverAcknowledgment.pending, (state, action) => {
      state[action.meta.arg.purpose].signed = true;
      state[action.meta.arg.purpose].forceRun = false;
    });
    resetStoreActionsUsingBuilder(builder, () => initialState);
  },
});

export const globalWaiverReducer = globalWaiverSlice.reducer;
export const { forceRun, forceStop } = globalWaiverSlice.actions;
