import React, { useState } from 'react';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker, DesktopDatePickerProps } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import classNames from 'classnames/bind';

import { INVALID_DATE_MESSAGE, formatDate } from '@common/utils/dateTimeUtil';

import { TextField } from '../TextField';

import styles from './DatePicker.module.css';

const cn = classNames.bind(styles);

export type DatePickerProps = {
  className?: string;
  label?: string;
  variant?: 'outlined' | 'standard';
  name?: string;
  helperText?: string;
  required?: boolean;
  fullWidth?: boolean;
  error?: boolean;
  formatter?: (value?: string) => string;
  autoComplete?: string;
  id?: string;
} & Pick<
  DesktopDatePickerProps<Date | string | number, Date | string | number>,
  'onChange' | 'disabled' | 'inputFormat' | 'value' | 'minDate' | 'maxDate' | 'mask' | 'inputRef'
>;

export function DatePicker({
  className,
  disabled = false,
  error = false,
  helperText = '',
  label,
  name = '',
  onChange,
  required = false,
  fullWidth = false,
  value = '',
  minDate,
  maxDate,
  mask,
  inputFormat = 'MM/dd/yyyy',
  formatter,
  id,
  inputRef,
  autoComplete,
}: DatePickerProps) {
  const [viewValue, setViewValue] = useState('');
  const handleChange = (formattedValue: string | number | Date | null, rawValue?: string) => {
    let newValue = rawValue;
    let newFormattedValue = formattedValue;
    if (formatter) {
      newValue = formatter(rawValue);

      newFormattedValue =
        formattedValue?.toString?.() === INVALID_DATE_MESSAGE &&
        formatDate(newValue, inputFormat) !== INVALID_DATE_MESSAGE
          ? formatDate(new Date(newValue), inputFormat)
          : formattedValue;

      setViewValue(newValue);
    }

    onChange(newFormattedValue, newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        className={cn('root', className)}
        inputFormat={inputFormat}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        label={label}
        minDate={minDate}
        maxDate={maxDate}
        mask={mask}
        disableMaskedInput={!!formatter}
        inputRef={inputRef}
        OpenPickerButtonProps={{
          'aria-haspopup': 'dialog',
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={id}
            value={viewValue || params.value}
            name={name}
            inputProps={{
              ...params.inputProps,
              value: viewValue || params.value || params.inputProps?.value,
              type: 'text',
            }}
            required={required}
            helperText={helperText}
            error={error}
            fullWidth={fullWidth}
            autoComplete={autoComplete}
          />
        )}
        PopperProps={{ className: cn('popper') }}
      />
    </LocalizationProvider>
  );
}
