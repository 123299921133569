import React, { useMemo } from 'react';

import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';
import { Accordion, AccordionDetails, AccordionSummary } from '@components/Accordion';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { locale } from '../../locale';

import styles from './Description.module.css';

const cn = classNames.bind(styles);

export const Description = () => {
  const { isMobileView } = useScreenSize();

  const renderInfo = useMemo(
    () => (
      <>
        <Typography className={cn('description__sub-title')} component="h2" variant="h3">
          {locale.description.subtitle}
        </Typography>
        <ul className={cn('description__use-to-items')}>
          {locale.description.use_to_items.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
        <p className={cn('description__additional')}>{locale.description.additional}</p>
      </>
    ),
    [],
  );

  return (
    <div className={cn('description')}>
      <header>
        <h1 className={cn('description__logo-container')}>
          <SvgIcon icon="mainLogo" className={cn('description__logo')} viewBox="0 0 305 99" />
          <span className="sr-only">Marathon Health Portal. </span>
        </h1>
      </header>
      <div className={cn('description__information')}>
        {!isMobileView ? (
          <>
            <Typography className={cn('description__title')} variant="h1" component="p">
              {locale.description.title}
            </Typography>
            {renderInfo}
          </>
        ) : (
          <Accordion className={cn('description__accordion')}>
            <AccordionSummary
              className={cn('description__accordion-summary')}
              expandedClassName={cn('description__accordion-summary--expanded')}
              contentClassName={cn('description__accordion-summary--content')}
              expandIcon={<SvgIcon icon="chevronDownBig" className={cn('description__accordion-icon')} />}
            >
              <Typography className={cn('description__title')} variant="h1" component="p">
                {locale.description.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={cn('description__accordion-details')}>{renderInfo}</AccordionDetails>
          </Accordion>
        )}
      </div>
    </div>
  );
};
