import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { StatusEnum } from '@common/constants';
import { useIgnoreEffectDeps } from '@common/hooks';
import { Markdown } from '@components/Markdown';
import { Container } from '@components/Modal';
import { Skeleton } from '@components/Skeleton';
import {
  contentByPurposeSelector,
  getWaiver,
  GlobalWeaverPurposes,
  statusByPurposeSelector,
} from '@modules/globalWeaver';

export const TermsOfUse = () => {
  const dispatch = useDispatch();
  const { body } = useSelector((state: RootState) =>
    contentByPurposeSelector(state, { purpose: GlobalWeaverPurposes.TermsOfUse }),
  );
  const status = useSelector((state: RootState) =>
    statusByPurposeSelector(state, { purpose: GlobalWeaverPurposes.TermsOfUse }),
  );

  useIgnoreEffectDeps(() => {
    if (!body && status !== StatusEnum.Pending) {
      dispatch(getWaiver(GlobalWeaverPurposes.TermsOfUse));
    }
  }, []);

  return <Container>{body ? <Markdown markdown={body ?? ''} /> : <Skeleton />}</Container>;
};
