import React, { KeyboardEventHandler, ReactNode } from 'react';

import MenuItemMaterial, { MenuItemProps as MenuItemMaterialProps } from '@mui/material/MenuItem';
import classNames from 'classnames/bind';
import { Link as RouterLink } from 'react-router-dom';

import { IconType, SvgIcon } from '@components/SvgIcon';

import styles from './MenuItem.module.css';

type sizes = 'small';

export type MenuItemProps = {
  className?: string;
  noHover?: boolean;
  noSelect?: boolean;
  leftIcon?: IconType;
  size?: sizes;
  to?: string;
  href?: string;
  replace?: boolean;
  badge?: ReactNode;
  disableFocusVisible?: boolean;
  contentClassName?: string;
  onClick?: VoidFunction;
  onKeyDown?: KeyboardEventHandler<HTMLElement>;
  onKeyUp?: KeyboardEventHandler<HTMLElement>;
  component?: 'div' | 'li' | null;
} & Pick<
  MenuItemMaterialProps,
  | 'children'
  | 'autoFocus'
  | 'selected'
  | 'value'
  | 'disableRipple'
  | 'focusVisibleClassName'
  | 'tabIndex'
  | 'divider'
  | 'aria-label'
  | 'aria-labelledby'
  | 'aria-describedby'
  | 'role'
>;

const cn = classNames.bind(styles);

export function MenuItem({
  autoFocus,
  children,
  className,
  onClick,
  selected,
  noHover,
  noSelect,
  leftIcon,
  value,
  disableRipple = true,
  onKeyDown,
  size,
  contentClassName,
  focusVisibleClassName,
  to,
  replace = false,
  href,
  onKeyUp,
  divider,
  tabIndex = 0,
  disableFocusVisible,
  role,
  component,
  badge,
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledBy,
  'aria-describedby': ariaDescribedby,
}: MenuItemProps) {
  const commonProps = {
    onClick,
    tabIndex,
    autoFocus,
    className: cn('menu-item', className, {
      [`menu-item--${size}`]: size,
    }),
    classes: {
      root: cn({
        'menu-item--no-hover': noHover,
        'menu-item--no-select': noSelect,
      }),
    },
    focusVisibleClassName: cn(focusVisibleClassName, {
      'menu-item--focus-visible': !disableFocusVisible,
    }),
    disableRipple,
    selected,
    onKeyDown,
    value,
    onKeyUp,
    divider,
    role,
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    'aria-describedby': ariaDescribedby,
  };

  const childrenWithIcon = (
    <>
      <div className={cn('menu-item__content', contentClassName)}>
        {leftIcon && <SvgIcon className={cn('menu-item__icon')} icon={leftIcon} inheritViewBox />}
        {children}
      </div>
      {badge}
    </>
  );

  if (to) {
    return (
      <MenuItemMaterial {...commonProps} component={RouterLink} to={to} replace={replace}>
        {childrenWithIcon}
      </MenuItemMaterial>
    );
  }
  if (href) {
    return (
      <MenuItemMaterial {...commonProps} component="a" href={href} target="_blank" rel="noreferrer">
        {childrenWithIcon}
      </MenuItemMaterial>
    );
  }

  return (
    // use 'div' component, because of using 'a' above, and have to replace default container component with 'div' in Menu.tsx
    <MenuItemMaterial component={component || 'div'} {...commonProps}>
      {childrenWithIcon}
    </MenuItemMaterial>
  );
}
