import React, { ElementType, forwardRef, ReactNode, Ref, useMemo } from 'react';

import ButtonMaterial, { ButtonProps as ButtonPropsMaterial } from '@mui/material/Button';
import classNames from 'classnames/bind';

import { IconType, SvgIcon } from '@components/SvgIcon';

import styles from './Button.module.css';

export type ButtonProps = {
  className?: string;
  disableRipple?: boolean;
  endIcon?: IconType;
  endComponent?: ReactNode;
  iconClass?: string;
  fullWidth?: boolean;
  loading?: boolean;
  id?: string;
  noHover?: boolean;
  startIcon?: IconType;
  startComponent?: ReactNode;
  buttonRootClass?: string;
  high?: 'low' | 'medium';
  variant?: ButtonPropsMaterial['variant'] | 'underline';
  color?: ButtonPropsMaterial['color'] | 'purple' | 'primary';
  component?: ElementType;
  target?: '_blank' | '_self' | '_parent' | '_top';
  to?: string;
  state?: unknown;
  rel?: string;
  ariaExpanded?: boolean;
  buttonDisabledClass?: string;
  disableFocusVisible?: boolean;
} & Pick<
  ButtonPropsMaterial,
  | 'children'
  | 'disabled'
  | 'size'
  | 'type'
  | 'tabIndex'
  | 'title'
  | 'role'
  | 'aria-describedby'
  | 'href'
  | 'autoFocus'
  | 'onClick'
  | 'onFocus'
  | 'style'
  | 'aria-label'
  | 'aria-disabled'
  | 'aria-controls'
  | 'aria-haspopup'
>;

type Props = ButtonProps & {
  forwardedRef: Ref<HTMLButtonElement>;
};

const cn = classNames.bind(styles);

export function ButtonComponent({
  children,
  className,
  color,
  disabled,
  disableRipple = true,
  endIcon,
  endComponent,
  fullWidth = false,
  iconClass,
  id,
  noHover = false,
  loading = false,
  onClick,
  size = 'medium',
  high = 'medium',
  startIcon,
  startComponent,
  variant = 'text',
  buttonRootClass,
  forwardedRef,
  ariaExpanded,
  'aria-disabled': ariaDisabled,
  'aria-label': ariaLabel,
  buttonDisabledClass,
  disableFocusVisible,
  ...buttonProps
}: Props) {
  const renderedStartIcon = useMemo(() => {
    if (startComponent) {
      return startComponent;
    }
    if (startIcon) {
      return <SvgIcon className={cn('button__icon', iconClass)} icon={startIcon} inheritViewBox />;
    }

    return null;
  }, [startComponent, startIcon, iconClass]);

  const renderedEndIcon = useMemo(() => {
    if (endComponent) {
      return endComponent;
    }

    if (loading) {
      return <SvgIcon icon="loading" className={cn('button__icon', 'button__loading-icon')} inheritViewBox />;
    }

    if (endIcon) {
      return <SvgIcon className={cn('button__icon', iconClass)} icon={endIcon} inheritViewBox />;
    }

    return null;
  }, [endComponent, endIcon, iconClass, loading]);

  return (
    <ButtonMaterial
      {...buttonProps}
      ref={forwardedRef}
      className={cn('button', className, {
        [`button--${variant}`]: !!variant,
        [`button--${color}`]: !!color,
        'button--high-low': high === 'low',
        'button--high-medium': high === 'medium',
        [`button--${size}`]: !!size,
        'button--no-hover': noHover,
        'button--full-width': fullWidth,
        'Mui-disabled': ariaDisabled,
        'button--disabled': ariaDisabled,
      })}
      aria-disabled={ariaDisabled}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      disabled={loading || disabled}
      onClick={!ariaDisabled ? onClick : undefined}
      id={id}
      disableRipple={disableRipple}
      size={size}
      classes={{
        startIcon: cn('button__icon--start'),
        endIcon: cn('button__icon--end'),
        root: cn('button__root', buttonRootClass),
        disabled: buttonDisabledClass,
      }}
      startIcon={renderedStartIcon}
      endIcon={renderedEndIcon}
      focusVisibleClassName={cn({
        'button--focus-visible': !disableFocusVisible,
        'button--focus-visible--rounded': !disableFocusVisible && variant === 'text',
      })}
    >
      {children}
    </ButtonMaterial>
  );
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => (
  <ButtonComponent forwardedRef={ref} {...props} />
));

Button.displayName = 'Button';
