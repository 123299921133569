import React, { MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { PATHS } from '@common/routes';
import { Button } from '@components/Button';
import { Menu } from '@components/Menu';
import { SizesEnum } from '@components/NumberCircle';
import { IconType } from '@components/SvgIcon';
import { isOnlyCurrentUserUnreadMessagesSelector, UnreadMessages } from '@modules/Messages';
import { HideFlippersEnum, ShowFlippersEnum } from '@modules/flippers';

import { useLogoutMenuItem } from '../../hooks/useLogoutMenuItem';
import { useViewSwitcherMenuitems } from '../../hooks/useViewSwitcherMenuItems';
import { ProfileMenu } from '../ProfileMenu';
import { locale } from './locale';

import styles from './ProfileMenuDropdown.module.css';

const cn = classNames.bind(styles);

export interface ProfileMenuProps {
  triggerButtonText: string;
}

const MENU_ID = 'profile-menu';
const TRIGGER_ID = 'profile-button';

export function ProfileMenuDropdown({ triggerButtonText }: ProfileMenuProps) {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorEl);
  const isOnlyCurrentUserUnreadMessages = useSelector(isOnlyCurrentUserUnreadMessagesSelector);

  const handleTriggerButtonClick = useCallback<MouseEventHandler>(
    (event) => {
      if (menuAnchorEl !== event.currentTarget) {
        setMenuAnchorEl(event.currentTarget as HTMLElement);
      }
    },
    [menuAnchorEl],
  );

  const closeMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setTimeout(() => {
      menuAnchorEl?.focus();
    });
    closeMenu();
  }, [menuAnchorEl, closeMenu]);

  const location = useLocation();
  useEffect(() => {
    closeMenu();
  }, [location, closeMenu]);

  const viewSwitcherMenuItems = useViewSwitcherMenuitems({ onViewSwitch: closeMenu });

  const logoutMenuItem = useLogoutMenuItem();

  const MENU_ITEMS = useMemo(
    () => [
      {
        icon: 'accountCircle' as IconType,
        to: PATHS.PROFILE.ROOT,
        label: locale.links.profile,
      },
      ...(viewSwitcherMenuItems.length > 1 ? viewSwitcherMenuItems : []),
      {
        icon: 'message' as IconType,
        to: PATHS.MESSAGES.ROOT,
        label: locale.links.messages,
        hideFlipper: HideFlippersEnum.hide_secure_messaging,
        badge: <UnreadMessages className={cn('profile-menu__messages')} size={SizesEnum.Small} />,
        replace: true,
        menuItemClassName: cn({ 'profile-menu__dropdown-item--badged': !isOnlyCurrentUserUnreadMessages }),
      },
      {
        icon: 'form' as IconType,
        to: PATHS.FORMS.ROOT,
        label: locale.links.forms,
      },
      {
        icon: 'union' as IconType,
        to: PATHS.COVID_SCREENING.ROOT,
        label: locale.links.covid,
        showFlipper: ShowFlippersEnum.covid_screening,
      },
      logoutMenuItem,
    ],
    [viewSwitcherMenuItems, isOnlyCurrentUserUnreadMessages, logoutMenuItem],
  );

  return (
    <div className={cn('profile-menu')}>
      <Button
        endIcon="chevronDown"
        onClick={handleTriggerButtonClick}
        id={TRIGGER_ID}
        variant={open ? 'contained' : 'text'}
        aria-controls={open ? MENU_ID : undefined}
        aria-haspopup="true"
        ariaExpanded={open}
        title={locale.open_dropdown}
        className={cn('profile-menu__button')}
      >
        {triggerButtonText}
      </Button>
      <Menu
        id={MENU_ID}
        anchorEl={menuAnchorEl}
        open={open}
        onClose={handleCloseMenu}
        aria-labelledby={TRIGGER_ID}
        MenuListProps={{
          className: cn('profile-menu__content'),
        }}
        PopoverClasses={{
          paper: cn('profile-menu__popover'),
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ProfileMenu
          onClose={closeMenu}
          menuItems={MENU_ITEMS}
          menuItemClassName={cn('profile-menu__dropdown-item')}
          groupedMenuItemClassName={cn('profile-menu__dropdown-item--grouped')}
          menuItemContentClassName={cn('profile-menu__dropdown-item-content')}
        />
      </Menu>
    </div>
  );
}
