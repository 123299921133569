import React from 'react';

import { TextField, TextFieldProps } from '@components/TextField';
import { Typography } from '@components/Typography';

import { useController, UseControllerProps, RegisterOptions, FieldValues } from '../hooks';
import { locale } from '../locale';

export type TextFieldWithControlProps<FormValues extends FieldValues> = {
  name: string;
  required?: string | boolean;
  min?: { value: number; message: string } | number;
  max?: { value: number; message: string } | number;
  maxLength?: { value: number; message: string } | number;
  minLength?: { value: number; message: string } | number;
  pattern?: { value: RegExp; message: string } | RegExp;
  validate?: RegisterOptions<FormValues>['validate'];
  deps?: RegisterOptions<FormValues>['deps'];
  rows?: number | undefined | string;
  id?: string;
  showCount?: boolean;
  counterClassName?: string;
} & UseControllerProps<FormValues> &
  Omit<TextFieldProps, 'maxLength' | 'minLength' | 'pattern' | 'max' | 'min' | 'required'>;

export function TextFieldWithControl<FormValues extends FieldValues>({
  control,
  autoComplete,
  name,
  required,
  min,
  max,
  maxLength = 255,
  minLength,
  pattern,
  onChange,
  validate,
  deps,
  rows,
  showCount,
  counterClassName,
  ...restProps
}: TextFieldWithControlProps<FormValues>) {
  const requiredMessage = typeof required === 'boolean' && required ? locale.required_message : required;

  const {
    field: { onChange: controllerOnChange, onBlur, value, ref },
    fieldState,
  } = useController({
    name,
    control,
    rules: { required: requiredMessage, maxLength, minLength, pattern, min, max, validate, deps },
  });

  return (
    <>
      <TextField
        {...restProps}
        required={!!required}
        autoComplete={autoComplete}
        min={typeof min === 'number' ? min : min?.value}
        max={typeof max === 'number' ? max : max?.value}
        maxLength={typeof maxLength === 'number' ? maxLength : maxLength?.value}
        minLength={typeof minLength === 'number' ? minLength : minLength?.value}
        pattern={pattern instanceof RegExp ? pattern : pattern?.value}
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        onChange={onChange || controllerOnChange}
        onBlur={onBlur}
        value={value ?? ''}
        name={name}
        rows={rows}
        inputRef={ref}
      />
      {showCount && (
        <Typography className={counterClassName} variant="body2">
          {value?.length || 0} / {maxLength}
        </Typography>
      )}
    </>
  );
}
