import React from 'react';

import classNames from 'classnames/bind';

import { Button } from '@components/Button';
import { IconType, SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { InternalErrorType } from './constants';
import { locale } from './locale';

import styles from './InternalError.module.css';

const cn = classNames.bind(styles);

type Action = {
  icon: IconType;
  action: () => void;
  title: string;
};

type Props = {
  type?: InternalErrorType;
  actions?: Array<Action>;
};

const ICON_MAP = {
  [InternalErrorType.ServiceError]: 'magnifier',
  [InternalErrorType.NotFound]: 'stethoscope',
  [InternalErrorType.Maintenance]: 'cone',
};

const ICON_LOGO_MAP = {
  [InternalErrorType.ServiceError]: 'marathonLogoDry',
  [InternalErrorType.NotFound]: 'mainLogo',
  [InternalErrorType.Maintenance]: 'marathonLogoDry',
};

export function InternalError({ type = InternalErrorType.ServiceError, actions }: Props) {
  return (
    <div
      className={cn('root', {
        [`root--${type}`]: type,
      })}
    >
      <nav>
        <SvgIcon className={cn('logo')} viewBox="0 0 305 99" icon={ICON_LOGO_MAP[type] as IconType} />
      </nav>
      <div
        className={cn('picture-container', {
          [`picture-container--${type}`]: type,
        })}
      >
        <SvgIcon
          className={cn('picture', {
            [`picture--${type}`]: type,
          })}
          viewBox="0 0 689 689"
          icon={ICON_MAP[type] as IconType}
        />
      </div>

      <div>
        <Typography
          className={cn('heading', {
            [`heading--${type}`]: type,
          })}
          variant="internal-error"
        >
          {locale[type].heading}
        </Typography>
        <Typography
          className={cn('sub-text', {
            [`sub-text--${type}`]: type,
          })}
        >
          {locale[type].subText}
        </Typography>

        <Typography
          className={cn('sub-heading', {
            [`sub-heading--${type}`]: type,
          })}
          variant="h2"
        >
          {locale[type].subHeading}
        </Typography>
        {actions && (
          <div className={cn('button-container')}>
            {actions.map(({ icon, title, action }) => (
              <Button
                key={title}
                onClick={action}
                className={cn('button', {
                  [`button--${type}`]: type,
                })}
                variant="text"
                startIcon={icon}
              >
                {title}
              </Button>
            ))}
          </div>
        )}
      </div>
      {!!locale[type]?.gratitude && (
        <Typography
          className={cn('gratitude', {
            [`gratitude--${type}`]: type,
          })}
        >
          {locale[type]?.gratitude}
        </Typography>
      )}
      {!!locale[type]?.errorCode && (
        <div className={cn('error-container')}>
          <div className={cn('error-container-content')}>
            <Typography
              className={cn('error-text', {
                [`error-text--${type}`]: type,
              })}
            >
              {locale[type]?.error}
            </Typography>
            <Typography
              className={cn('error-code', {
                [`error-code--${type}`]: type,
              })}
            >
              {locale[type]?.errorCode}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
}
