import React, { ReactNode, useCallback, useMemo, useState, ChangeEvent, ReactElement } from 'react';

import FormControl from '@mui/material/FormControl';
import SelectMaterial, { SelectProps as SelectPropsMaterial } from '@mui/material/Select';
import classNames from 'classnames/bind';

import { FormHelperText } from '@components/FormHelperText';
import { InputLabel } from '@components/InputLabel';
import { MenuItem, MenuItemProps } from '@components/Menu';
import { SvgIcon } from '@components/SvgIcon';
import { InputAdornment } from '@components/TextField';

import styles from './Select.module.css';

const cn = classNames.bind(styles);

export type OptionType = { id: string | number; name: string | number };

export type SelectProps<CustomOptionType = OptionType> = {
  className?: string;
  selectClassName?: string;
  menuClassName?: string;
  selectRootClassName?: string;
  selectDisabledClassName?: string;
  helperText?: Nullable<string>;
  idKey?: string;
  loading?: boolean;
  nameKey?: string;
  noneOption?: OptionType;
  clearValue?: boolean;
  options?: Array<CustomOptionType>;
  selectOption?: boolean;
  hideCurrentOption?: boolean;
  chevronIcon?: ReactElement;
  renderOptionValue?: (option: CustomOptionType) => string | ReactNode;
  renderOption?: (option: CustomOptionType, i?: number) => string | ReactNode;
  renderOptions?: (options: CustomOptionType[]) => string | ReactNode;
  ariaLabel?: string;
  ariaDescribedby?: string;
  labelId: string;
  itemRole?: MenuItemProps['role'];
  optionComponent?: 'li' | 'div' | null;
  hiddenLabel?: boolean;
  autoComplete?: string;
} & Pick<
  SelectPropsMaterial,
  | 'disabled'
  | 'onChange'
  | 'value'
  | 'label'
  | 'error'
  | 'variant'
  | 'fullWidth'
  | 'placeholder'
  | 'required'
  | 'name'
  | 'multiple'
  | 'renderValue'
  | 'onOpen'
  | 'onClose'
  | 'onFocus'
  | 'MenuProps'
  | 'open'
  | 'disableUnderline'
  | 'SelectDisplayProps'
  | 'inputRef'
>;

export function Select<CustomOptionType = OptionType>({
  className,
  selectClassName,
  MenuProps = {},
  menuClassName,
  selectRootClassName,
  chevronIcon,
  selectDisabledClassName,
  disabled = false,
  hideCurrentOption = false,
  error = false,
  fullWidth,
  helperText = '',
  idKey = 'id',
  label,
  loading = false,
  multiple = false,
  name,
  nameKey = 'name',
  noneOption,
  onChange,
  options = [],
  placeholder = '',
  required = false,
  selectOption = false,
  value = multiple ? [] : '',
  variant = 'standard',
  renderValue,
  renderOptionValue,
  renderOption,
  renderOptions,
  onFocus,
  onOpen,
  onClose,
  clearValue,
  open,
  labelId,
  disableUnderline,
  ariaLabel,
  ariaDescribedby = undefined,
  SelectDisplayProps,
  inputRef,
  itemRole,
  optionComponent,
  hiddenLabel,
  autoComplete,
}: SelectProps<CustomOptionType>) {
  const defaultValue = useMemo(() => (multiple ? [] : ''), [multiple]);
  const [focused, setFocused] = useState(false);

  const loadingIcon = useCallback(
    (props: { className?: string }) => (
      <SvgIcon icon="loading" className={cn('select__icon', 'select__loading-icon', props.className)} />
    ),
    [],
  );

  const chevronDownIcon = useCallback(
    (props: { className?: string }) =>
      chevronIcon || <SvgIcon icon="chevronDown" className={cn('select__icon', props.className)} />,
    [chevronIcon],
  );

  const handleOpen: SelectPropsMaterial['onOpen'] = (event) => {
    onOpen?.(event);
    setFocused(true);
  };

  const handleClose: SelectPropsMaterial['onClose'] = (event) => {
    onClose?.(event);
    setFocused(false);
  };

  const getId = useCallback(<T,>(item: T): unknown => (item as Nullable<UnknownObject>)?.[idKey], [idKey]);
  const getName = useCallback(<T,>(item: T): unknown => (item as Nullable<UnknownObject>)?.[nameKey], [nameKey]);

  const customRenderValue = useCallback(
    (value: unknown): ReactNode => {
      if (placeholder && [null, ''].includes(value as string | null)) {
        return placeholder;
      }

      if (selectOption && options?.length && typeof value === 'object') {
        return getName(options.find((option) => getId(option) === getId(value))) as ReactNode;
      }

      return (getName(options.find((option) => getId(option) === value)) || value) as ReactNode;
    },
    [getName, options, placeholder, getId, selectOption],
  );

  const selectMaterialValue: unknown = useMemo(() => {
    if (Array.isArray(value)) {
      return value;
    }

    if (!value) {
      return value;
    }

    if (selectOption && options?.length && idKey) {
      if (typeof value === 'object') {
        return options.find((option) => getId(option) === getId(value)) ?? defaultValue;
      }

      return options.find((option) => getId(option) === value) ?? defaultValue;
    }

    if (typeof value === 'object') {
      return getName(value);
    }

    return value;
  }, [selectOption, options, value, getName, getId, idKey, defaultValue]);

  const getOptionValue = useCallback(
    (option: CustomOptionType) => {
      if (renderOptionValue) {
        return renderOptionValue(option);
      }

      return nameKey ? getName(option) : option;
    },
    [renderOptionValue, nameKey, getName],
  );

  const handleClear = useCallback(() => {
    const event = {
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>;

    onChange?.(event, '');
  }, [onChange]);

  const filteredOptions = useMemo(() => {
    if (hideCurrentOption) {
      return options.filter((option) =>
        selectOption && typeof value === 'object' ? getId(option) !== getId(value) : getId(option) !== value,
      );
    }

    return options;
  }, [hideCurrentOption, options, value, getId, selectOption]);

  const ariaDescId = helperText ? `${labelId}_description` : ariaDescribedby;

  return (
    <FormControl
      variant={variant}
      data-testid="select"
      disabled={disabled || loading}
      fullWidth={fullWidth}
      className={cn('select', className)}
      classes={{
        root: cn('select__control-root', {
          'select__control-root--outlined': variant === 'outlined',
          'select__control-root--outlined-with-label': variant === 'outlined' && label,
        }),
      }}
    >
      {label && (
        <InputLabel required={required} htmlFor={`input_${labelId}`} id={labelId} error={error}>
          {label}
        </InputLabel>
      )}
      <SelectMaterial
        id={`select-id_${labelId}`}
        autoComplete={autoComplete}
        inputRef={inputRef}
        className={cn('select__input', selectClassName, {
          'select--focused': focused,
          'select--outlined': variant === 'outlined',
        })}
        classes={{
          select: cn('select__root', selectRootClassName),
          outlined: cn('select__input-outlined'),
          disabled: selectDisabledClassName,
        }}
        disabled={disabled || loading}
        error={error}
        open={open}
        fullWidth={fullWidth}
        IconComponent={loading ? loadingIcon : chevronDownIcon}
        inputProps={{
          readOnly: loading,
          id: `input_${labelId}`,
        }}
        aria-describedby={ariaDescId}
        aria-label={ariaLabel}
        multiple={multiple}
        name={name}
        labelId={!hiddenLabel ? labelId : undefined}
        onChange={onChange}
        displayEmpty
        placeholder={placeholder}
        value={selectMaterialValue}
        variant={variant}
        disableUnderline={disableUnderline}
        aria-labelledby={`select-id_${labelId}`}
        MenuProps={{
          ...MenuProps,
          classes: {
            paper: cn('select__menu', menuClassName, {
              [`select__menu--${variant}`]: variant,
            }),
          },
        }}
        endAdornment={
          clearValue ? (
            <InputAdornment position="end" className={cn('select__clear-button')}>
              <SvgIcon icon="close" fontSize="small" onClick={handleClear} inheritViewBox />
            </InputAdornment>
          ) : undefined
        }
        renderValue={renderValue || customRenderValue}
        onOpen={handleOpen}
        onFocus={onFocus}
        onClose={handleClose}
        SelectDisplayProps={SelectDisplayProps}
      >
        {noneOption && (
          <MenuItem
            role={itemRole}
            size="small"
            value={(selectOption || !idKey ? noneOption : getId(noneOption)) as string}
          >
            {(nameKey ? getName(noneOption) : noneOption) as ReactNode}
          </MenuItem>
        )}
        {renderOptions
          ? renderOptions(options)
          : filteredOptions.map((option: CustomOptionType, i) =>
              renderOption ? (
                renderOption(option, i)
              ) : (
                <MenuItem
                  role={itemRole}
                  size="small"
                  value={(selectOption || !idKey ? option : getId(option)) as string}
                  key={(idKey ? getId(option) : option) as string}
                  component={optionComponent}
                >
                  {getOptionValue(option) as ReactNode}
                </MenuItem>
              ),
            )}
      </SelectMaterial>

      {helperText && <FormHelperText error={error} helperText={helperText} id={ariaDescId} />}
    </FormControl>
  );
}
