import { PATHS } from '@common/routes';

import { locale } from './locale';

export enum ModalsEnum {
  EmergencyReasons = 'emergencyReasons',
  BehavioralReason = 'behavioralReason',
  WorkRelatedInjury = 'workRelatedInjury',
}

export const COVID_VACCINE_ID = 256;
export const PHYSICAL_THERAPY_IDS = [252];

export const BREADCRUMBS_ITEMS = {
  HOME: {
    title: locale.breadcrumbs.home,
    to: PATHS.MAIN_PAGE,
  },
  SCHEDULE_APPOINTMENT: {
    title: locale.breadcrumbs.appointments,
    to: PATHS.APPOINTMENTS.SCHEDULE_APPOINTMENT,
  },
  COMPLETE_LIST: {
    title: locale.breadcrumbs.complete_ist,
    to: PATHS.APPOINTMENTS.COMPLETE_LIST_OF_VISIT_REASONS,
  },
};
