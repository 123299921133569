import React, { ReactNode, useCallback } from 'react';

import usePagination, { UsePaginationProps, UsePaginationItem } from '@mui/material/usePagination';
import classNames from 'classnames/bind';

import { useScreenSize } from '@common/hooks/useScreenSize';

import { PaginationButton } from './components/PaginationButton';
import { locale } from './locale';

import styles from './Pagination.module.css';

export type PaginationProps = {
  count: number;
  disabled?: boolean;
  hideNextButton?: boolean;
  hidePrevButton?: boolean;
  onChange?: UsePaginationProps['onChange'];
  page: number;
  showFirstButton?: boolean;
  showLastButton?: boolean;
  className?: string;
  tiny?: boolean;
  siblingCount?: number;
};

const cn = classNames.bind(styles);

const PAGINATION_TYPES = {
  PREVIOUS: 'previous',
  NEXT: 'next',
  PAGE: 'page',
  FIRST: 'first',
  LAST: 'last',
  START_ELLIPSIS: 'start-ellipsis',
  END_ELLIPSIS: 'end-ellipsis',
};

const FIRST_PAGE = 1;

export function Pagination({
  count,
  disabled = false,
  hideNextButton,
  hidePrevButton,
  onChange,
  page = FIRST_PAGE,
  showFirstButton = false,
  showLastButton,
  className,
  siblingCount,
  tiny = false,
}: PaginationProps) {
  const { isMobileView } = useScreenSize();
  const { items } = usePagination({
    count,
    disabled,
    hideNextButton,
    hidePrevButton,
    onChange,
    page,
    showFirstButton,
    showLastButton,
    siblingCount,
  });
  const renderButtonText = useCallback(
    (type: UsePaginationItem['type']) => {
      if (tiny || isMobileView) {
        return null;
      }

      return locale.types[type];
    },
    [isMobileView, tiny],
  );

  return (
    <nav className={cn('pagination', className)}>
      <ul className={cn('pagination__items')}>
        {items.map(({ page, type, ...item }) => {
          let children: ReactNode = (
            <PaginationButton {...item} page={page} withMargin>
              {page}
            </PaginationButton>
          );

          if ([PAGINATION_TYPES.PREVIOUS, PAGINATION_TYPES.NEXT].includes(type)) {
            children = (
              <PaginationButton
                {...item}
                startIcon={type === PAGINATION_TYPES.PREVIOUS ? 'chevronLeft' : undefined}
                endIcon={type === PAGINATION_TYPES.NEXT ? 'chevronRight' : undefined}
                ariaLabel={`${locale.got_to} ${locale.labels[type]} ${locale.types.page}`}
              >
                {renderButtonText(type)}
              </PaginationButton>
            );
          }

          if ([PAGINATION_TYPES.FIRST, PAGINATION_TYPES.LAST].includes(type)) {
            children = (
              <PaginationButton
                {...item}
                startIcon={type === PAGINATION_TYPES.FIRST ? 'chevronDuoLeft' : undefined}
                endIcon={type === PAGINATION_TYPES.LAST ? 'chevronDuoRight' : undefined}
                ariaLabel={`${locale.got_to} ${locale.labels[type]} ${locale.types.page}`}
              >
                {renderButtonText(type)}
              </PaginationButton>
            );
          }

          if ([PAGINATION_TYPES.START_ELLIPSIS, PAGINATION_TYPES.END_ELLIPSIS].includes(type)) {
            children = '…';
          }

          return (
            <li key={page + type} className={cn('pagination__item')}>
              {children}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
