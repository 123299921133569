import { WellnessTypesEnum } from '@modules/WellnessLog';

import { locale } from './locale';
import { generateWellnessLogPath } from './utils';

export enum FilterIdsEnum {
  IsCompleted = 'is_completed',
  IsIncented = 'is_incented',
}

export const MOCK_ITEM = {
  name: 'Exercise',
  description: 'Learn more about healthy exercise habits and how to get started in this four week workshop.',
  last_completed: null,
  current_progress: 0,
  is_completed: false,
  is_incented: false,
  id: 13,
};

export const FILTER_ALL_ITEM = {
  id: null,
  name: locale.all,
};

export const FILTER_OPTIONS = [
  { id: '', name: locale.filter_names.all },
  { id: FilterIdsEnum.IsCompleted, name: locale.filter_names.is_completed },
  { id: FilterIdsEnum.IsIncented, name: locale.filter_names.is_incented },
];

export const MAP_WELLNESS_LINKS: UnknownObject<null | ((id: string) => string)> = {
  '/my/fitness_activities': generateWellnessLogPath(WellnessTypesEnum.Fitness),
  '/my/sleep_trackings': generateWellnessLogPath(WellnessTypesEnum.Sleep),
  '/my/weight_entries': generateWellnessLogPath(WellnessTypesEnum.Weight),
  '/my/health_recordings/new': null,
};

export const HEALTHWISE_LINK = 'https://www.healthwise.net/marathon-health/Content/StdDocument.aspx?DOCHWID=';
