import React, { ReactNode, useCallback } from 'react';

import { INVALID_DATE_MESSAGE, differenceInDays } from '@common/utils';
import { notRequiredShortDateValidatorWithMessage } from '@common/utils/dateValidator';
import { dateFormatter } from '@common/utils/formatters/dateFormatter';
import { FieldRow } from '@components/FieldRow';
import { DatePickerWithControl, TimePickerWithControl, FieldValues, UseFormReturn, Path } from '@components/Form';
import { Grid } from '@components/Grid';

import { GRID_SPACING, GRID_ROW_SPACING } from '../constants';
import { locale } from '../locale';

type DateTimePickerProps<FormValuesType extends FieldValues> = {
  dateLabel: string;
  timeLabel: string;
  isLoading: boolean;
  label?: ReactNode;
  inputRef?: React.Ref<HTMLInputElement> | undefined;
} & Pick<UseFormReturn<FormValuesType>, 'control'>;

const inputFormat = 'MM/dd/yy';

const maxDate = new Date();
const formatter = dateFormatter({ inputFormat, maxDate });

export function DateTimePicker<FormValuesType extends FieldValues>({
  control,
  isLoading,
  dateLabel,
  timeLabel,
  label,
  inputRef,
}: DateTimePickerProps<FormValuesType>) {
  const minDate = new Date().setFullYear(new Date().getFullYear() - 4);

  const handleDateValidation = useCallback(
    (value: unknown) => {
      if (differenceInDays(new Date(value as string), minDate) < 0) {
        return locale.errors.date;
      }

      return notRequiredShortDateValidatorWithMessage(value);
    },
    [minDate],
  );

  return (
    <FieldRow label={label} size="small">
      <Grid container rowSpacing={GRID_ROW_SPACING} columnSpacing={GRID_SPACING}>
        <Grid item xs={12} md={6}>
          <DatePickerWithControl
            name={'date' as Path<FormValuesType>}
            control={control}
            label={dateLabel}
            required
            disabled={isLoading}
            fullWidth
            inputFormat={inputFormat}
            mask="__/__/__"
            validate={handleDateValidation}
            minDate={minDate}
            formatter={formatter}
            maxDate={new Date()}
            inputRef={inputRef}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TimePickerWithControl
            name={'time' as Path<FormValuesType>}
            control={control}
            fullWidth
            label={timeLabel}
            required={{
              value: true,
              message: locale.errors.time,
            }}
            validate={(value: string | number) => value?.toString?.() !== INVALID_DATE_MESSAGE}
            disabled={isLoading}
            placeholder="00:00 AM"
          />
        </Grid>
      </Grid>
    </FieldRow>
  );
}
