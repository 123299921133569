import React from 'react';

import classNames from 'classnames/bind';

import { SvgIcon } from '@components/SvgIcon';
import { TextFieldProps, TextFieldDebounced, InputAdornment } from '@components/TextField';

import { locale } from './locale';

import styles from './SearchField.module.css';

const cn = classNames.bind(styles);

type Props = TextFieldProps & {
  onSearch: TextFieldProps['onChange'];
  debounceDelay?: number;
  leftIcon?: boolean;
  onClear?: VoidFunction;
  inputRootClassName?: string;
  inputClassName?: string;
};

export const SearchField = ({
  value,
  placeholder,
  onSearch,
  leftIcon,
  debounceDelay = 500,
  className,
  onClear,
  inputRootClassName,
  inputClassName,
  ...props
}: Props) => (
  <div className={cn('root', className)}>
    <TextFieldDebounced
      className={cn('search-input')}
      inputClassName={cn('input', inputClassName, { 'input--left-icon': leftIcon })}
      inputRootClassName={cn(inputRootClassName)}
      variant="outlined"
      placeholder={placeholder || locale.search}
      value={value}
      onChange={onSearch}
      name={placeholder || locale.search}
      InputProps={{
        classes: {
          root: cn('input-root'),
        },
        startAdornment: leftIcon && (
          <InputAdornment position="start">
            <SvgIcon
              fontSize="inherit"
              icon="search"
              viewBox="0 0 17 17"
              className={cn('search-icon', 'search-icon--left')}
            />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="start">
            {!leftIcon && (
              <SvgIcon fontSize="inherit" icon="search" viewBox="0 0 16 16" className={cn('search-icon')} />
            )}

            {!!onClear && !!value && (
              <SvgIcon
                fontSize="inherit"
                icon="close"
                viewBox="0 0 17 17"
                className={cn('clear-icon')}
                inheritViewBox
                onClick={onClear}
              />
            )}
          </InputAdornment>
        ),
      }}
      debounceDelay={debounceDelay}
      fullWidth
      {...props}
    />
  </div>
);
