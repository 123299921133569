import React from 'react';

import classNames from 'classnames/bind';

import { useIgnoreEffectDeps } from '@common/hooks';
import { CheckboxWithControl, Control } from '@components/Form';
import { Markdown } from '@components/Markdown';
import { Skeleton } from '@components/Skeleton';

import styles from '../SignModal.module.css';

const cn = classNames.bind(styles);

type Props = {
  control?: Control<{ agreement: boolean }>;
  onSubmit: () => void;
  loading?: boolean;
  getData?: () => void;
  body: Nullable<string>;
  agreementTitle?: string;
  agreementFieldName?: string;
};

export const SignModalContent = ({
  control,
  onSubmit,
  loading,
  getData,
  body,
  agreementTitle,
  agreementFieldName,
}: Props) => {
  useIgnoreEffectDeps(() => {
    getData?.();
  }, []);

  return (
    <form onSubmit={onSubmit} className={cn('modal-content')}>
      {loading ? <Skeleton className={cn('skeleton')} variant="rectangular" /> : body && <Markdown markdown={body} />}
      {agreementFieldName && control && (
        <CheckboxWithControl
          control={control}
          name="agreement"
          label={agreementTitle}
          required
          checkBoxClassName={cn('agreement')}
        />
      )}
    </form>
  );
};
