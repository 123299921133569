import React from 'react';

import classNames from 'classnames/bind';

import { useQueryParamsObject } from '@common/router';
import { getLocale } from '@common/utils/locale';
import { SvgIcon } from '@components/SvgIcon';
import { Typography } from '@components/Typography';

import { MailboxTypeEnum } from '../../../../constants';
import { locale } from '../../locale';

import styles from './ConversationNoMessages.module.css';

const cn = classNames.bind(styles);

export const ConversationNoMessages = () => {
  const { mailboxType, search } = useQueryParamsObject();

  return (
    <div className={cn('root')}>
      <SvgIcon className={cn('icon')} icon="emptyBox" inheritViewBox />
      <div>
        <Typography className={cn('title')} variant="h2">
          {getLocale(search ? locale.empty_search_box : locale.empty_box, {
            mailbox: locale.mailbox_types[mailboxType as MailboxTypeEnum],
            search,
          })}
        </Typography>
        <Typography className={cn('sub-title')} variant="h4" component="div">
          {search ? locale.empty_search_box_try_again : locale.all_your_message}
        </Typography>
      </div>
    </div>
  );
};
