import React, { MouseEventHandler, useCallback, useMemo } from 'react';

import classNames from 'classnames/bind';

import { Link } from '@components/Link';
import { SvgIcon } from '@components/SvgIcon';

import styles from './Attachment.module.css';

const cn = classNames.bind(styles);

export type Props = {
  className?: string;
  bodyClassName?: string;
  onDelete?: () => void;
  url?: string;
  name: string;
  size?: number | string;
};

export const Attachment = ({ url, name, className, bodyClassName, onDelete, size }: Props) => {
  const handleOnDelete = useCallback<MouseEventHandler>(
    (event) => {
      event?.stopPropagation();
      event.preventDefault();

      onDelete?.();
    },
    [onDelete],
  );

  const attachmentBody = useMemo(
    () => (
      <>
        <SvgIcon icon="attachments" className={cn('icon')} />
        <div className={cn(bodyClassName)}>
          {name}
          {size && <div className={cn('size')}>{size}</div>}
        </div>
        {onDelete && <SvgIcon icon="close" onClick={handleOnDelete} className={cn('icon', 'icon--delete')} />}
      </>
    ),
    [bodyClassName, handleOnDelete, name, onDelete, size],
  );

  if (!url) {
    return <div className={cn('attachment', className)}>{attachmentBody}</div>;
  }

  return (
    <Link className={cn('attachment', className)} to={url ?? ''} target="_blank" noUnderline>
      <SvgIcon icon="attachments" className={cn('icon')} />
      <div className={cn('attachment-body', bodyClassName)}>
        {name}
        {size && <div className={cn('size')}>{size}</div>}
      </div>
      {onDelete && <SvgIcon icon="close" onClick={handleOnDelete} className={cn('icon', 'icon--delete')} />}
    </Link>
  );
};
